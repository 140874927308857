import { SVG_ENUM } from "enums";
import SVG from "shared/SVG";
import { AnalyzeStatus } from "types/AnalyzeTypes";
import "./AnalyzeStatusBadge.scss";

const AnalyzeStatusBadge = ({
  status,
  className,
}: {
  status: AnalyzeStatus;
  className?: string;
}) => {
  const statusToText = {
    inProgress: "W trakcie analizy",
    completed: "Przeanalizowana",
    canceled: "Odrzucona",
    pending: "Do weryfikacji",
  };

  const statusToIcon = {
    pending: SVG_ENUM.SEARCH,
    inProgress: SVG_ENUM.ARROW_RELOAD,
    completed: SVG_ENUM.CIRCLE_CHECK,
    canceled: SVG_ENUM.STOP_SIGN,
  };

  return (
    <span className={`analyze-status analyze-status--${status} ${className}`}>
      <p className="analyze-status__text">{statusToText[status]}</p>{" "}
      <SVG type={statusToIcon[status]} />
    </span>
  );
};

export default AnalyzeStatusBadge;
