import { SVG_ENUM } from "enums";
import { useGetPostCategories } from "hooks/posts";
import { SVG } from "shared";
import { isDesktop } from "utils";
import CategoriesModal from "../CategoriesModal/CategoriesModal";
import { useState } from "react";

interface Props {
  selectedCategories: number[];
  setSelectedCategories: (value: number[]) => void;
}

const PostCategories = ({
  setSelectedCategories,
  selectedCategories,
}: Props) => {
  const { data: categoryOptions } = useGetPostCategories();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCategoryClick = (id: number) => {
    if (selectedCategories.includes(id)) {
      setSelectedCategories(selectedCategories.filter((item) => item !== id));
    } else {
      setSelectedCategories([...selectedCategories, id]);
    }
  };
  return (
    <div className="posts-grid__categories">
      {isDesktop() ? (
        <>
          <span
            className={`posts-grid__category ${
              selectedCategories.length === 0
                ? "posts-grid__category--active"
                : ""
            }`}
            onClick={() => setSelectedCategories([])}
          >
            Wszystkie
          </span>
          {categoryOptions?.map((category) => (
            <span
              className={`posts-grid__category ${
                selectedCategories.includes(category.value)
                  ? "posts-grid__category--active"
                  : ""
              }`}
              onClick={() => handleCategoryClick(category.value)}
            >
              {category.label}
              <div className={`posts-grid__category-icon `}>
                <SVG type={SVG_ENUM.CLOSE} />
              </div>
            </span>
          ))}
        </>
      ) : (
        <>
          <CategoriesModal
            handleCategoryClick={handleCategoryClick}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
          <span
            onClick={() => setIsModalOpen(true)}
            className={`posts-grid__filter-button ${
              selectedCategories.length
                ? "posts-grid__filter-button--active"
                : ""
            }`}
          >
            <SVG type={SVG_ENUM.FILTER} />
          </span>
        </>
      )}
    </div>
  );
};

export default PostCategories;
