/* eslint-disable react-hooks/exhaustive-deps */
import Table from "rc-table";
import { mobileColumns, desktopColumns, summaryColumns } from "./utils";
import { isDesktop } from "utils";
// import { data } from "utils/userBase";
import { CompanyMyType } from "types/CompanyTypes";
import { Tooltip } from "react-tooltip";
import "./TransactionsTable.scss";
import { useGetTransaction } from "hooks/transactions";

interface TransactionsTableType {
  company: CompanyMyType | null;
}

const TransactionsTable = ({ company }: TransactionsTableType) => {
  const shareId = company?.sharesId
    ? String(company.sharesId)
    : company?.shares?.[0]?.id
    ? String(company?.shares[0].id)
    : "";
  const { data } = useGetTransaction(shareId);
  return (
    <div className="companies-table transactions-table">
      <Tooltip
        place="top"
        className="tooltip tooltip--noShadow"
        id="company-tooltip"
      />
      {company && (
        <Table
          rowKey="id"
          data={data}
          columns={
            isDesktop() ? desktopColumns(company) : mobileColumns(company)
          }
        />
      )}
      <div className="transactions-table__summary">
        {isDesktop() && company && (
          <Table
            rowKey="id"
            data={[company]}
            columns={summaryColumns(company)}
          />
        )}
      </div>
    </div>
  );
};

export default TransactionsTable;
