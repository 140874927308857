import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { Toast } from "shared";
import { TOAST_TYPES_ENUM } from "enums";
// import { queryClient } from "App";
import { UpdatePaymentMethodType } from "types/PaymentTypes";
import { queryClient } from "App";

export const useDeletePaymentMethod = (
  options?: UseMutationOptions<any, Error, UpdatePaymentMethodType>
) => {
  return useMutation({
    ...options,
    mutationKey: ["deletePaymentMethod"],
    mutationFn: async (id) => {
      return axiosInstance
        .delete(`/payment-methods/${id}`)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Ładowanie...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "delete-card",
      });
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ["paymentMethods"] });
      queryClient.invalidateQueries({ queryKey: ["payments"] });
      Toast({
        message: "Karta usunięta pomyślnie.",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "delete-card",
      });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: "Błąd podczas usuwania karty.",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "delete-card",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useDeletePaymentMethod;
