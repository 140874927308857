import { PACKET_ENUM, SVG_ENUM } from "enums";
import { EditAddressDataModal } from "features/users";
import { useGetMe } from "hooks/auth";
import { useCommunicationPreference } from "hooks/users";
import React, { useEffect, useState } from "react";
import { SVG, Warning } from "shared";
import { UserType } from "types/UsersTypes";
import { Formik, Form } from "formik";
import CommunicationRow from "./CommunicationRow";
import CommunicationBanner from "./CommunicationBanner";
import "./Communication.scss";
import { isPacketEnought } from "utils";
import { useGetSettings } from "hooks/settings";

interface CommunicationProps {
  user: UserType | undefined;
  handleGoBack: () => void;
}

const Communication: React.FC<CommunicationProps> = ({
  user,
  handleGoBack,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: authorizedUser } = useGetMe();
  const { mutate: updateCommunication } = useCommunicationPreference();
  const [initialValues, setInitialValues] = useState<any>({});
  const { data: settings } = useGetSettings();

  useEffect(() => {
    const settingsWithCommunication = settings?.filter(
      (setting) =>
        setting.name.includes("-sms") || setting.name.includes("-email")
    );
    let initialData: any = [];
    settingsWithCommunication?.forEach((setting) => {
      initialData.push({
        [setting.name]: setting.valueBoolean,
      });
    });
    setInitialValues(Object.assign({}, ...initialData));
  }, [settings]);

  const handleFormSubmit = (values: any) => {
    let newValue: UserType["communicationPreference"] = null;

    const hasSms = Object.values(values.notifications).some(
      (item) => (item as any).sms
    );
    const hasEmail = Object.values(values.notifications).some(
      (item) => (item as any).email
    );

    if (hasSms && hasEmail) newValue = "both";
    else if (hasSms) newValue = "sms";
    else if (hasEmail) newValue = "email";

    if (authorizedUser && authorizedUser.id) {
      updateCommunication({
        communicationPreference: newValue,
      });
    }
  };

  return (
    <div className="profile__right-wrapper communication">
      <EditAddressDataModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        user={user || null}
      />
      <div className="profile__right-back" onClick={() => handleGoBack()}>
        <SVG type={SVG_ENUM.ARROW_LEFT} /> Wróć
      </div>
      <h2 className="profile__menu-header">
        <span className="profile__menu-icon profile__menu-icon--right">
          <SVG type={SVG_ENUM.USER} />
        </span>
        Komunikacja
      </h2>

      <h3 className="profile__menu-subheader">
        Proszę wybrać preferowany sposób komunikacji. Powiadomienia mogą być
        przesyłane za pośrednictwem wiadomości SMS oraz/lub na adres email.
      </h3>

      {!authorizedUser?.email && !!authorizedUser?.package && (
        <Warning
          text={
            <p>
              Aby włączyć wysyłkę powiadomień na adres email,{" "}
              <b>
                prosimy o uzupełnienie adresu email w zakładce „Dane osobowe"
              </b>
            </p>
          }
          styleType={"gray"}
        />
      )}

      {!authorizedUser?.package && (
        <Warning
          text={
            <p>
              Powiadomienia są dostępne tylko w ramach płatnych pakietów. Aby
              odblokować tę funkcję <b>wykup jeden z dostępnych pakietów.</b>
            </p>
          }
          styleType={"gray"}
        />
      )}

      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {({ values, handleSubmit }) => (
          <Form>
            <div className="communication__grid">
              <div className="communication__grid-header">
                <div></div>
                <div className="column-title">SMS</div>
                <div className="column-title">Email</div>
              </div>

              <CommunicationRow
                title="Spółki wykupione w Reliance"
                description="Informacje o nowych wydarzeniach i aktualizacjach związanych ze spółkami wykupionymi w Reliance."
                disabled={!isPacketEnought(user?.package, PACKET_ENUM.BASIC)}
                settingsKey="isSendBoughtInReliance"
                emailDisabled={!authorizedUser?.email}
              />

              <CommunicationRow
                title="Spółki w ofercie Reliance"
                description="Informacje o nowych wydarzeniach i aktualizacjach dotyczących spółek dostępnych w ofercie Reliance."
                disabled={!isPacketEnought(user?.package, PACKET_ENUM.STANDARD)}
                settingsKey="isSendInOffer"
                emailDisabled={!authorizedUser?.email}
              />

              <CommunicationRow
                title="Spółki wykupione poza Reliance"
                description="Informacje o nowych wydarzeniach i aktualizacjach związanych ze spółkami wykupionymi poza Reliance."
                disabled={!isPacketEnought(user?.package, PACKET_ENUM.PREMIUM)}
                settingsKey="isSendBoughtOutside"
                emailDisabled={!authorizedUser?.email}
              />

              <CommunicationRow
                title="Analizy rynkowe"
                description="Powiadomienia o statusie przeprowadzania analizy rynkowej — otrzymasz informację, gdy analiza zostanie rozpoczęta, zakończona i będzie dostępna do pobrania."
                disabled={!isPacketEnought(user?.package, PACKET_ENUM.PRO)}
                settingsKey="isSendMarketAnalysis"
                emailDisabled={!authorizedUser?.email}
              />
            </div>
          </Form>
        )}
      </Formik>

      {authorizedUser?.package !== PACKET_ENUM.PRO &&
        authorizedUser?.package !== PACKET_ENUM.PREMIUM &&
        !!authorizedUser?.package && <CommunicationBanner />}
    </div>
  );
};

export default Communication;
