import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { queryClient } from "App";
import axiosInstance from "axiosConfig";
import { ActivateSubscription } from "types/SubscriptionTypes";

export const useActivateSubscription = (
  options?: UseMutationOptions<any, Error, ActivateSubscription>
) => {
  return useMutation({
    ...options,
    mutationKey: ["activateSubscription"],
    mutationFn: async (data) => {
      return axiosInstance
        .post("/subscriptions", {
          ...data,
        })
        .then((res) => res.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["customers"] });
      queryClient.invalidateQueries({ queryKey: ["subscriptions"] });
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
  });
};

export default useActivateSubscription;
