import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { PostStatusType, PostType } from "types/PostTypes";
import { MetaType } from "types/UtilsTypes";

export default function useGetPosts({
  page,
  enabled = true,
  status,
  companyId,
  categoryIds,
  isAdmin,
  type,
}: {
  page: number;
  enabled?: boolean;
  status?: PostStatusType;
  companyId?: number;
  categoryIds?: number[];
  isAdmin?: boolean;
  type?: string;
}) {
  return useQuery({
    queryKey: ["posts", page, status, companyId, type, categoryIds],
    queryFn: async () => {
      return axiosInstance
        .get(
          `/posts${isAdmin ? "" : "/user"}?page=${page}
          ${status ? `&status=${status}` : ""} 
          ${companyId ? `&companyId=${companyId}` : ""}
          ${categoryIds ? `&categoryIds=[${categoryIds}]` : ""}
          ${type && !isAdmin ? `&type=${type}` : ""}
            `
        )
        .then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: { data: PostType[]; meta: MetaType }) => data,
    retry: false,
    enabled,
    staleTime: 1000 * 15,
  });
}
