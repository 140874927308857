import { SVG_ENUM } from "enums";
import "./Banner.scss";
import { Button } from "shared";
import SVG from "shared/SVG";

interface BannerProps {
  buttonLabel?: string;
  buttonOnClick?: () => void;
  text: string | JSX.Element;
  className?: string;
  bannerImage?: string;
}

const Banner: React.FC<BannerProps> = ({
  text,
  buttonLabel,
  buttonOnClick,
  className = "",
  bannerImage = "CommunicationBanner.webp",
}: BannerProps) => {
  return (
    <div className={`banner ${className}`}>
      <div className="banner__content">
        <div className="banner__svg">
          <SVG type={SVG_ENUM.LOCK} />
        </div>
        <div className="banner__text">{text}</div>
      </div>
      <img
        className="banner__image"
        src={require(`assets/images/${bannerImage}`)}
        alt=" banner"
      />
      {!!buttonLabel && (
        <Button
          label={buttonLabel}
          styleType="strokeGray"
          size="small"
          onClick={() => buttonOnClick && buttonOnClick()}
          className="banner__button"
        />
      )}
    </div>
  );
};

export default Banner;
