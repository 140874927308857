import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";
import getPrefixFromUuid from "../getPrefixFromUuid";
import { VALIDATION_ENUM } from "enums";

export interface PhoneValidationResult {
  isValid: boolean;
  error?: string;
}

/**
 * Validates a phone number for any country
 * @param phoneNumber The raw phone number string
 * @param phonePrefixUuid The UUID of the phone prefix (country code)
 * @param prefixes Array of available prefixes from the API
 * @returns An object with validation result and optional error message
 */
export const validatePhoneNumber = (
  phoneNumber: string,
  phonePrefixUuid: string,
  prefixes: Array<{ value: string; label: string }>
): PhoneValidationResult => {
  // If any required parameter is missing, return invalid
  if (!phoneNumber || !phonePrefixUuid || !prefixes.length) {
    return { isValid: false, error: VALIDATION_ENUM.REQUIRED };
  }

  // Get the prefix (country code) from UUID
  const prefix = getPrefixFromUuid(phonePrefixUuid, prefixes);
  if (!prefix) {
    return { isValid: false, error: VALIDATION_ENUM.REQUIRED };
  }

  // Remove the '+' from the prefix to get just the country code
  const countryCode = prefix.replace("+", "");

  // Format number with country code for validation
  const fullPhoneNumber = `+${countryCode}${phoneNumber}`;

  try {
    // Parse the phone number
    const parsedNumber = parsePhoneNumberFromString(fullPhoneNumber);

    // If couldn't parse, it's invalid
    if (!parsedNumber) {
      return { isValid: false, error: VALIDATION_ENUM.REQUIRED };
    }

    // Check if the number is valid for the specified country
    if (!parsedNumber.isValid()) {
      return {
        isValid: false,
        error: VALIDATION_ENUM.INVALID_PHONE_NUMBER_FOR_THIS_COUNTRY,
      };
    }

    // Additional check using the direct isValidPhoneNumber method
    if (!isValidPhoneNumber(fullPhoneNumber)) {
      return { isValid: false, error: VALIDATION_ENUM.INVALID_PHONE_NUMBER };
    }

    return { isValid: true };
  } catch (error) {
    console.error("Phone validation error:", error);
    return { isValid: false, error: VALIDATION_ENUM.REQUIRED };
  }
};
