import { DashboardTopBar, EditorJsOutput, SVG } from "shared";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_ENUM, SVG_ENUM } from "enums";
import { useState } from "react";

import { PostType } from "types/PostTypes";
import { useGetPost } from "hooks/posts";
import "./PostPage.scss";

export interface PostModalManage {
  type: string;
  post: PostType | null;
}

const PostPage = () => {
  const [imageLoading, setImageLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split("/").pop();
  const { data, isLoading } = useGetPost({ id: id ? parseInt(id) : 0 });
  const company = data?.companies?.length ? data.companies[0] : null;

  return (
    <>
      <div className="companies">
        <DashboardTopBar>
          <h1 className="dashboard__header">Informacje rynkowe</h1>
        </DashboardTopBar>
        <div className="dashboard-content__wrapper">
          <div className="dashboard-content">
            <div
              className="post-page__back"
              onClick={() => navigate(ROUTE_ENUM.MARKET_INFORMATION)}
            >
              <SVG type={SVG_ENUM.ARROW_LEFT} />
              Wróć
            </div>

            <div className="post-page__content">
              <div className="post-item__image post-page__image">
                {imageLoading && <Skeleton height={188} />}
                {data ? (
                  data?.file?.url ? (
                    <img
                      src={data.file.url}
                      alt={data.title}
                      onLoad={() => setImageLoading(false)}
                      onError={(e: any) => {
                        e.target.src = require("assets/images/PostPlaceholder.webp");
                        setImageLoading(false);
                      }}
                    />
                  ) : (
                    <img
                      src={require("assets/images/PostPlaceholder.webp")}
                      alt={data?.title}
                      onLoad={() => setImageLoading(false)}
                    />
                  )
                ) : null}
              </div>
              <div className="post-item__content">
                {isLoading ? (
                  <>
                    <Skeleton
                      width={100}
                      height={28}
                      className="post-item__company-user"
                    />
                    <Skeleton
                      width={300}
                      height={24}
                      className="post-item__title-user"
                    />
                    <div className="post-item__link">
                      Źródło: <Skeleton width={150} height={20} />
                    </div>
                    <Skeleton count={3} className="post-item__content-data" />
                  </>
                ) : (
                  <>
                    <p className="post-item__company-user">{company?.name}</p>
                    <h2 className="post-item__title-user">{data?.title}</h2>
                    <div className="post-item__link">
                      Źródło:{" "}
                      <a href={data?.link} target="_blank" rel="noreferrer">
                        <SVG type={SVG_ENUM.LINK} />
                        {data?.source}
                      </a>
                    </div>
                    <p className="post-item__content-data post-page__content-data">
                      {data?.content && (
                        <EditorJsOutput
                          outputData={JSON.parse(data?.content)}
                        />
                      )}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostPage;
