import { SVG_ENUM, VALIDATION_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useAddAdmin, useUpdateUser } from "hooks/users";
import { useState } from "react";
import { Button, FullscreenModal, Input, SVG, Switch } from "shared";
import { UserType } from "types/UsersTypes";
import * as Yup from "yup";

interface AddAdminModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  user?: UserType | null;
}

const AddAdminModal: React.FC<AddAdminModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  user,
}) => {
  const { mutateAsync: createAdmin } = useAddAdmin();
  const { mutateAsync: updateUser } = useUpdateUser();
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    name: user?.name || "",
    email: user?.email || "",
    isSendInvoice: user?.isSendInvoice || false,
  };

  const onSubmit = async (values: typeof initialValues) => {
    setIsSubmitting(true);

    try {
      if (!!user) {
        await updateUser(
          { ...user, ...values },
          {
            onSuccess: () => {
              onSuccess && onSuccess();
              onClose();
            },
            onError: () => setShowError(true),
          }
        );
      } else {
        await createAdmin(values, {
          onSuccess: () => {
            onSuccess && onSuccess();
            onClose();
          },
          onError: () => setShowError(true),
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const validation = () =>
    Yup.object().shape({
      name: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .max(50)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
      email: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .email(VALIDATION_ENUM.WRONG_EMAIL)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
    });

  return (
    <FullscreenModal isOpen={isOpen} onClose={onClose}>
      <h2 className="fullscreen-modal__header">
        {!!user ? "Edycja administratora" : "Dodawanie administratora"}
      </h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={validation()}
        enableReinitialize
      >
        {({ errors, values, handleSubmit, setFieldValue }) => (
          <Form className="m-width-100">
            <p
              className={`login-admin__error ${
                showError ? "login-admin__error--show" : ""
              }`}
            >
              Podany email jest już zajęty
              <SVG type={SVG_ENUM.CLOSE_CIRCLE} />
            </p>
            <Field
              type="text"
              id="name"
              name="name"
              label={"Imię i nazwisko"}
              as={Input}
              maxLength={50}
            />

            <Field
              type="text"
              id="email"
              name="email"
              label={"Email"}
              as={Input}
              onFocus={() => setShowError(false)}
              maxLength={50}
            />
            <div className="fullscreen-modal__switch">
              Wysyłka powiadomień o nowych analizach rynkowych do wykonania
              <Switch
                state={values.isSendInvoice}
                onClick={() =>
                  setFieldValue("isSendInvoice", !values.isSendInvoice)
                }
              />
            </div>

            <div className="fullscreen-modal__buttons">
              <Button
                label="Anuluj"
                onClick={onClose}
                className="button--stroke button--rounded"
              />
              <Button
                label={!!user ? "Zapisz" : "Dodaj"}
                type="submit"
                onClick={() => null}
                className=" button--rounded button--black "
                isLoading={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </FullscreenModal>
  );
};

export default AddAdminModal;
