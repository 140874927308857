import { SVG_ENUM } from "enums";
import { useGetPostCategories } from "hooks/posts";
import { useEffect, useState } from "react";
import { Button, EmptyModal, SVG } from "shared";

interface CategoriesModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCategories: number[];
  handleCategoryClick: (id: number) => void;
  setSelectedCategories: (value: number[]) => void;
}

export default function CategoriesModal({
  isOpen,
  onClose,
  selectedCategories,
  handleCategoryClick,
  setSelectedCategories,
}: CategoriesModalProps) {
  const { data: categoryOptions } = useGetPostCategories();
  const [categoriesOnInit, setCategoriesOnInit] = useState<number[]>([]);

  useEffect(() => {
    if (isOpen) {
      setCategoriesOnInit(selectedCategories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleOnClose = () => {
    setSelectedCategories(categoriesOnInit);
    onClose();
  };

  return (
    <EmptyModal
      title="Filtry"
      topLeftLabel="Wyczyść"
      topLeftOnClick={() => setSelectedCategories([])}
      isOpen={isOpen}
      onClose={handleOnClose}
    >
      <h2 className="select__label">Kategoria</h2>
      <div className="posts-grid__categories">
        <span
          className={`posts-grid__category ${
            selectedCategories.length === 0 ? "posts-grid__category--active" : ""
          }`}
          onClick={() => setSelectedCategories([])}
        >
          Wszystkie
          <div className={`posts-grid__category-icon `}>
            <SVG type={SVG_ENUM.CLOSE} />
          </div>
        </span>
        {categoryOptions?.map((category) => (
          <span
            className={`posts-grid__category ${
              selectedCategories.includes(category.value)
                ? "posts-grid__category--active"
                : ""
            }`}
            onClick={() => handleCategoryClick(category.value)}
          >
            {category.label}
            <div className={`posts-grid__category-icon `}>
              <SVG type={SVG_ENUM.CLOSE} />
            </div>
          </span>
        ))}
      </div>
      <div className="posts-grid__categories--bottom">
        <Button
          onClick={() => handleOnClose()}
          label="Anuluj"
          size="large"
          styleType="strokeBlack"
          className="button--wide"
        />
        <Button
          onClick={() => {
            onClose();
          }}
          label="Potwierdź"
          styleType="primary"
          size="large"
          className="button--wide"
        />
      </div>
    </EmptyModal>
  );
}
