import { VALIDATION_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useStoreCompanyClient, useUpdateCompanyClient } from "hooks/companies";
import { useGetCountriesCurrency } from "hooks/countries";
import { useGetIndustriesHook } from "hooks/industries";
import { useRef, useState } from "react";
import moment from "moment";
// import { useAddAdmin, useUpdateUser } from "hooks/users";
import {
  Button,
  CalendarInput,
  Checkbox,
  DropFileInput,
  FullscreenModal,
  Input,
  LargeSwitch,
  SelectComponent,
} from "shared";
import { CompanyMyType, CompanyStoreClientType } from "types/CompanyTypes";
import { getCurrencyFromUuid, getCurrencySymbol, scrollToError } from "utils";
import * as Yup from "yup";

interface AddCompanyClientModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  company?: CompanyMyType | null;
}

const AddCompanyClientModal: React.FC<AddCompanyClientModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  company,
}) => {
  const { mutateAsync: storeCompany } = useStoreCompanyClient();
  const { mutateAsync: updateCompany } = useUpdateCompanyClient();
  const { data: industries } = useGetIndustriesHook();
  const { data: currencyOptions } = useGetCountriesCurrency();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef<any>(null);

  const initialValues = {
    name: company?.name || "",
    industryUuid: company?.industryUuid || "",
    currencyUuid: company?.currencyUuid || "",
    sharesCount: company?.sharesCount || null,
    sharesValue: company?.sharesValue || null,
    invoice: null,
    completedAt:
      company?.firstTransaction?.completedAt || moment().format("YYYY-MM-DD"),
    type: company?.type || "stock",
    pricePerShare: company?.pricePerShare || null,
    nip: company?.nip || "",
    isHistoryAccepted: company?.isHistoryAccepted || false,
  };

  const onSubmit = async (values: CompanyStoreClientType) => {
    setIsSubmitting(true);
    try {
      const summary =
        values.sharesValue && values.sharesCount
          ? Number.parseFloat(
              (values.sharesValue / values.sharesCount).toFixed(2)
            )
          : 0;

      if (!!company) {
        await updateCompany(
          {
            ...company,
            ...values,
            sharesId: company.sharesId,
            pricePerShare: summary,
          },
          {
            onSuccess: () => {
              onClose();
            },
          }
        );
      } else {
        await storeCompany(
          { ...values, pricePerShare: summary },
          {
            onSuccess: () => {
              onSuccess && onSuccess();
              onClose();
            },
          }
        );
      }
    } finally {
      scrollToError(formRef.current?.errors);
      setIsSubmitting(false);
    }
  };

  const validation = () =>
    Yup.object().shape({
      name: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .max(50)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
      industryUuid: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      currencyUuid: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      sharesCount: Yup.number().required(VALIDATION_ENUM.REQUIRED),
      completedAt: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      type: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      isHistoryAccepted: Yup.boolean(),
    });

  return (
    <FullscreenModal isOpen={isOpen} onClose={onClose}>
      <h2 className="fullscreen-modal__header">
        {!!company ? "Edycja spółki" : "Dodaj spółkę"}
      </h2>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values: any) => await onSubmit(values)}
        validationSchema={validation()}
        enableReinitialize
        innerRef={formRef}
        validateOnMount={false}
      >
        {({
          errors,
          values,
          handleSubmit,
          setFieldValue,
          submitForm,
          isValid,
          submitCount,
        }) => {
          const summary =
            values?.sharesValue && values?.sharesCount
              ? (values?.sharesValue / values?.sharesCount).toFixed(2)
              : 0;
          const isStock = values?.type === "stock";
          return (
            <Form className="m-width-100 fullscreen-modal__select-margin">
              {!!company ? null : (
                <LargeSwitch
                  state={values.type}
                  className="add-company__switch"
                  options={[
                    { label: "Akcje", value: "stock" },
                    { label: "Udziały", value: "share" },
                  ]}
                  onChange={(value: string) => setFieldValue("type", value)}
                />
              )}
              <Field
                type="text"
                id="name"
                name="name"
                label={"Nazwa spółki"}
                as={Input}
                maxLength={100}
              />

              <Field
                label="Branża"
                name="industryUuid"
                id="industryUuid"
                as={SelectComponent}
                options={industries}
              />
              <Field
                label="Waluta"
                name="currencyUuid"
                id="currencyUuid"
                as={SelectComponent}
                options={currencyOptions}
                isSearchable
              />
              <Field
                type="number"
                id="sharesCount"
                name="sharesCount"
                label={`Ilość  ${isStock ? "akcji" : "udziałów"}`}
                as={Input}
                precision={3}
                maxLength={10}
              />
              <div className="input--currency">
                <Field
                  type="number"
                  id="sharesValue"
                  name="sharesValue"
                  optional
                  precision={2}
                  label={`Wartość  ${isStock ? "akcji" : "udziałów"}`}
                  as={Input}
                  maxLength={10}
                />
                <span>
                  {getCurrencySymbol(getCurrencyFromUuid(values.currencyUuid))}
                </span>
              </div>

              <div className={`add-share__summary`}>
                <label className="input__label">
                  Wartość {isStock ? "jednej akcji" : "jednego udziału"}
                </label>
                <input
                  type="text"
                  className="input"
                  readOnly
                  value={summary ? summary : 0}
                />
                <span>
                  {values.currencyUuid &&
                    getCurrencySymbol(getCurrencyFromUuid(values.currencyUuid))}
                </span>
              </div>

              <Field
                type="date"
                id="completedAt"
                name="completedAt"
                label={`Data pierwszego zakupu ${
                  values.type === "stock" ? "akcji" : "udziałów"
                }`}
                component={CalendarInput}
                errors={errors}
              />

              <Field
                label={<>Umowa</>}
                name="invoice"
                required
                id="invoice"
                as={DropFileInput}
                accept=".pdf"
                optional
              />

              <Field
                type="text"
                id="nip"
                name="nip"
                optional
                label={"NIP"}
                as={Input}
                maxLength={11}
              />

              <Field
                id="isHistoryAccepted"
                name="isHistoryAccepted"
                wrapperClassName="login-wrapper__acceptance checkbox__modal"
                label={
                  <p>
                    Udzielam zgody, by pracownicy firmy Reliance mieli dostęp do
                    historii moich transakcji związanych z tą spółką.
                  </p>
                }
                as={Checkbox}
              />

              <div className="fullscreen-modal__buttons">
                <Button
                  label="Anuluj"
                  onClick={onClose}
                  className="button--stroke button--rounded"
                />
                <Button
                  label={"Potwierdź"}
                  type="button"
                  onClick={async () => {
                    await submitForm().then(() => {
                      // After form validation is complete, check if there are errors and scroll to them
                      if (
                        Object.keys(formRef.current?.errors || {}).length > 0
                      ) {
                        scrollToError(formRef.current?.errors);
                      }
                    });
                  }}
                  className=" button--rounded button--black"
                  isLoading={isSubmitting}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </FullscreenModal>
  );
};

export default AddCompanyClientModal;
