import React, { useEffect, useState } from "react";
import { EnterCode, LoginWrapper, SVG } from "shared";
import "./EnterCodePage.scss";
import { Formik, Form } from "formik";
import { ROUTE_ENUM, SVG_ENUM, VALIDATION_ENUM } from "enums";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router";
import { useGetSmsCode, useLoginSms } from "hooks/auth";

type EnterCodePageProps = {};

const EnterCodePage: React.FC<EnterCodePageProps> = () => {
  const [code, setCode] = useState<string[]>(["", "", "", ""]);

  const navigate = useNavigate();
  const location = useLocation();
  const phoneNumber =
    location.state?.phoneNumber || sessionStorage.getItem("phoneNumber");
  const { mutate: getSmsCode } = useGetSmsCode();

  const { mutate } = useLoginSms({
    onSuccess: () => {
      if (sessionStorage.getItem("destinationPath")) {
        navigate(sessionStorage.getItem("destinationPath") as string);
        sessionStorage.removeItem("destinationPath");
      } else {
        navigate(ROUTE_ENUM.COMPANIES);
      }
    },
    onError: (error: Error) => {
      console.error("Błąd podczas logowania:", error.message);
    },
  });

  const validation = () =>
    Yup.object().shape({
      smsCode: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    });

  const initialValues = {
    smsCode: "",
  };

  const onSubmit = (values: typeof initialValues) => {
    const smsCode = code.join("");
    mutate({ smsCode });
  };

  useEffect(() => {
    const input = document.getElementById("code-input-0");
    input?.focus();
  }, []);

  useEffect(() => {
    if (code.join("").length === 4) {
      onSubmit({ smsCode: code.join("") });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <LoginWrapper className="enter-code-page">
      <span className="login-wrapper__back" onClick={() => navigate(-1)}>
        <SVG type={SVG_ENUM.ARROW_LEFT} />
      </span>
      <h1 className="login-wrapper__header">Wprowadź kod dostępu</h1>
      <p className="login-wrapper__subtitle">
        {phoneNumber ? (
          <>
            Wprowadź 4-cyfrowy kod, który przesłaliśmy w wiadomości SMS na
            numer:<b>{phoneNumber}</b>.
          </>
        ) : (
          "Wprowadź 4-cyfrowy kod, który przesłaliśmy Ci w wiadomości SMS."
        )}
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={validation()}
      >
        {({ errors, values, handleSubmit }) => (
          <Form>
            <EnterCode setCode={setCode} code={code} />

            <p
              className="login-wrapper__anchor enter-code-page__anchor"
              onClick={() => {
                getSmsCode({
                  phoneNumber: `${location.state.phone}`,
                  phonePrefixUuid: `${location.state.phonePrefixUuid}`,
                });
              }}
            >
              Nie otrzymałeś kodu? <span> Wyślij ponownie</span>
            </p>
          </Form>
        )}
      </Formik>
    </LoginWrapper>
  );
};

export default EnterCodePage;
