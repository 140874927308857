import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { SubscriptionType } from "types/SubscriptionTypes";
// import { ClientType } from "types/ClientType";

export default function useGetSubscription(enabled: boolean = true) {
  return useQuery({
    queryKey: ["subscriptions"],
    queryFn: async () => {
      return axiosInstance.get("/subscriptions").then((res) => res.data);
    },
    select: (data: SubscriptionType) => data,
    retry: false,
    // staleTime: 1000 * 60 * 5,
    enabled,
  });
}
