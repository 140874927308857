import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { AddPaymentMethodType } from "types/PaymentTypes";
import { Toast } from "shared";
import { TOAST_TYPES_ENUM } from "enums";
import { queryClient } from "App";

export const useStorePaymentMethod = (
  options?: UseMutationOptions<any, Error, AddPaymentMethodType>
) => {
  return useMutation({
    ...options,
    mutationKey: ["addPaymentMethod"],
    mutationFn: async ({ stripePmToken }) => {
      console.log("making something");
      return axiosInstance
        .post("/payment-methods", {
          stripe_pm_token: stripePmToken,
        })
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Ładowanie...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "add-payment-method",
      });
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ["paymentMethods"] });
      queryClient.invalidateQueries({ queryKey: ["payments"] });
      Toast({
        message: "Karta dodana pomyślnie.",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "add-payment-method",
      });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: "Błąd podczas dodawania karty.",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "add-payment-method",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useStorePaymentMethod;
