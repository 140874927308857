import { VALIDATION_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useStoreTransaction } from "hooks/transactions";
import { useState } from "react";
import moment from "moment";
import {
  Button,
  CalendarInput,
  DropFileInput,
  FullscreenModal,
  Input,
  LargeSwitch,
  Warning,
} from "shared";
import { CompanyMyType } from "types/CompanyTypes";
import { TransactionStoreType } from "types/TransactionsTypes";
import { getCurrencyFromUuid, getCurrencySymbol } from "utils";
import * as Yup from "yup";

interface SellSharesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  company: CompanyMyType | null;
}

const SellSharesModal: React.FC<SellSharesModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  company,
}) => {
  const { mutateAsync: storeTransaction } = useStoreTransaction();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isStock = company?.type === "stock";
  const initialValues = {
    sharesCount: null as number | null,
    pricePerShare: company?.pricePerShare || null,
    sharesValue: null as number | null,
    invoice: null,
    completedAt: moment().format("YYYY-MM-DD"),
    type: "sell",
  };

  const onSubmit = async (values: TransactionStoreType) => {
    setIsSubmitting(true);
    try {
      const summary =
        values.sharesValue && values.sharesCount
          ? Number.parseFloat(
              (values.sharesValue / values.sharesCount).toFixed(2)
            )
          : 0;

      if (company) {
        await storeTransaction(
          {
            ...values,
            sharesId: company?.sharesId,
            pricePerShare: summary || undefined,
          },
          {
            onSuccess: () => {
              onSuccess && onSuccess();
              onClose();
            },
          }
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const validation = () =>
    Yup.object().shape({
      sharesCount: Yup.number().required(VALIDATION_ENUM.REQUIRED),
      completedAt: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    });

  return (
    <FullscreenModal isOpen={isOpen} onClose={onClose}>
      <h2 className="fullscreen-modal__header">Zaznacz sprzedaż / kupno</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: any) => onSubmit(values)}
        validationSchema={validation()}
        enableReinitialize
      >
        {({ errors, values, handleSubmit, setFieldValue }) => {
          const summary =
            values.sharesValue && values.sharesCount
              ? (values.sharesValue / values.sharesCount).toFixed(2)
              : 0;

          const isSell = values.type === "sell";
          const isMoreThanAvailable =
            isSell &&
            company?.sharesCount &&
            values?.sharesCount &&
            values?.sharesCount > company?.sharesCount;

          return (
            <Form className="m-width-100 fullscreen-modal__select-margin">
              <LargeSwitch
                state={values.type}
                className="add-company__switch"
                options={[
                  { label: "Sprzedaż", value: "sell" },
                  { label: "Kupno", value: "buy" },
                ]}
                onChange={(value: string) => setFieldValue("type", value)}
              />
              <Field
                type="number"
                id="sharesCount"
                name="sharesCount"
                precision={3}
                label={`Ilość ${isSell ? "sprzedanych" : "kupionych"} ${
                  isStock ? "akcji" : "udziałów"
                }`}
                as={Input}
              />
              {isSell && (
                <Warning
                  className={isMoreThanAvailable ? "" : "warning--gray"}
                  text={`Nie możesz sprzedać więcej udziałów niż posiadasz. Obecnie posiadasz ${company?.sharesCount}.`}
                />
              )}
              <div className="input--currency">
                <Field
                  type="number"
                  id="sharesValue"
                  name="sharesValue"
                  maxLength={10}
                  optional
                  precision={2}
                  label={`Wartość ${isSell ? "sprzedanych" : "kupionych"} ${
                    isStock ? "akcji" : "udziałów"
                  }`}
                  as={Input}
                />
                <span>
                  {company?.currencyUuid
                    ? getCurrencySymbol(
                        getCurrencyFromUuid(company?.currencyUuid)
                      )
                    : null}
                </span>
              </div>
              <div className={`add-share__summary`}>
                <label className="input__label">
                  Wartość {isStock ? "jednej akcji" : "jednego udziału"}
                </label>
                <input
                  type="text"
                  className="input"
                  readOnly
                  value={summary ? summary : 0}
                />
                <span>
                  {company?.currencyUuid &&
                    getCurrencySymbol(
                      getCurrencyFromUuid(company?.currencyUuid)
                    )}
                </span>
              </div>
              <Field
                type="date"
                id="completedAt"
                name="completedAt"
                label={"Data transakcji"}
                component={CalendarInput}
                errors={errors}
              />

              <Field
                label="Umowa (opcjonalne)"
                name="invoice"
                id="invoice"
                as={DropFileInput}
                accept=".pdf"
              />

              <div className="fullscreen-modal__buttons">
                <Button
                  label="Anuluj"
                  onClick={onClose}
                  className="button--stroke button--rounded"
                />
                <Button
                  label={"Potwierdź"}
                  type="submit"
                  onClick={() => null}
                  disabled={!!isMoreThanAvailable}
                  className=" button--rounded button--black"
                  isLoading={isSubmitting}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </FullscreenModal>
  );
};

export default SellSharesModal;
