import { Field, Form, Formik } from "formik";
import { useGetCompaniesOptions } from "hooks/companies";
import { useGetPosts } from "hooks/posts";
import { useEffect, useState } from "react";
import {
  PostModalManage,
  PostTabsType,
  PostTypeType,
} from "screens/marketInfoPage/MarketInfoPage";
import { Pagination, SelectComponent } from "shared";
import "./PostsGrid.scss";
import PostItem from "../PostItem/PostItem";
import PostCategories from "../PostCategories/PostCategories";
import { isDesktop } from "utils";

interface PostsGridProps {
  tab: PostTabsType;
  setModalManage: React.Dispatch<React.SetStateAction<PostModalManage>>;
  companyId: number | null;
  setCompanyId: React.Dispatch<React.SetStateAction<number | null>>;
  type: PostTypeType;
}

const PostsGrid = ({
  tab,
  setModalManage,
  companyId,
  setCompanyId,
  type,
}: PostsGridProps) => {
  const [page, setPage] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState<number[]>(
    JSON.parse(sessionStorage.getItem("selectedCategories") || "[]")
  );

  useEffect(() => {
    sessionStorage.setItem(
      "selectedCategories",
      JSON.stringify(selectedCategories)
    );
  }, [selectedCategories]);

  const isAdmin = tab !== "user";
  const { data, isLoading } = useGetPosts({
    page,
    isAdmin,
    status: tab === "draft" || tab === "withdrawn" ? tab : undefined,
    companyId: companyId ? companyId : undefined,
    type: isAdmin ? undefined : type,
    categoryIds: selectedCategories.length ? selectedCategories : undefined,
  });

  const { data: companiesOptions } = useGetCompaniesOptions({});

  const handleChangePage = (e: number) => {
    setPage(e);
  };

  return (
    <div className={"posts-grid"}>
      <div
        className={`posts-grid__top ${isAdmin ? "posts-grid__top--admin" : ""}`}
      >
        {!isDesktop() && (
          <Formik
            initialValues={{
              companyId,
            }}
            onSubmit={(values: any) => console.log(values)}
            enableReinitialize
          >
            {() => (
              <Form className="posts-grid__search">
                <Field
                  label="Znajdź spółkę"
                  name="companyId"
                  id="companyId"
                  placeholder="Szukaj"
                  as={SelectComponent}
                  onSelectChange={(e: any) => {
                    setCompanyId(e?.value);
                    sessionStorage.setItem("selectedPostsCompany", e?.value);
                  }}
                  options={companiesOptions}
                  isClearable
                />
              </Form>
            )}
          </Formik>
        )}
        {!isAdmin && !isDesktop() && (
          <PostCategories
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
        )}
      </div>
      {!isAdmin && isDesktop() && (
        <>
          <h2 className="select__label">Kategoria</h2>
          <PostCategories
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
        </>
      )}
      <div
        className={`posts-grid__wrapper ${
          isAdmin ? "" : "posts-grid__wrapper--user"
        }`}
      >
        {isLoading
          ? // Show loading skeletons while data is loading
            Array.from({ length: 6 }).map((_, index) => (
              <PostItem
                key={index}
                data={{} as any}
                setModalManage={setModalManage}
                companyId={companyId}
                isLoading={true}
              />
            ))
          : data?.data.map((post) => (
              <PostItem
                key={post.id}
                data={post}
                setModalManage={setModalManage}
                companyId={companyId}
                isLoading={false}
              />
            ))}
      </div>

      {data?.meta && data?.meta?.lastPage > 1 && (
        <Pagination
          totalPages={data?.meta?.lastPage}
          currentPage={data?.meta?.currentPage || page}
          onPageChange={(e: any) => handleChangePage(e)}
        />
      )}
    </div>
  );
};

export default PostsGrid;
