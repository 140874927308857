import { useGetMyCompanies } from "hooks/companies";
import Table from "rc-table";
import { isDesktop } from "utils";
import { desktopColumns, mobileColumns } from "./utilsMyTab";
import CompaniesSummary from "../CompaniesSummary/CompaniesSummary";
import { CompanyMyType, CompanyType } from "types/CompanyTypes";
import { Banner, Button, DashboardTopPortal, EmptyState } from "shared";
import { useGetUnviewedPostsCount } from "hooks/posts";
import { useGetMe } from "hooks/auth";
import { PACKET_ENUM, SVG_ENUM } from "enums";

interface MyCompaniesTabType {
  tab: "my" | "other";
  onRowClick?: (record: any, e: React.MouseEvent<any, MouseEvent>) => void;
  setEditCompany: (company: any) => void;
  handleStatusChange: (company: any, status: any) => void;
  industries: any;
  setShowSellShares: (company: CompanyMyType) => void;
  setShowDelete: (company: CompanyMyType) => void;
  setModalManage: ({
    type,
    company,
  }: {
    type: string;
    company: CompanyType | CompanyMyType | null;
  }) => void;
}

const MyCompaniesTab = ({
  tab,
  onRowClick,
  setEditCompany,
  handleStatusChange,
  industries,
  setShowSellShares,
  setShowDelete,
  setModalManage,
}: MyCompaniesTabType) => {
  const { data: myRelianceData } = useGetMyCompanies({
    origin: "reliance",
    enabled: tab === "my",
  });
  const { data: myOtherData } = useGetMyCompanies({
    origin: "other",
    enabled: tab === "my",
  });

  const combinedIds = [...(myRelianceData || []), ...(myOtherData || [])].map(
    (item) => item.id
  );

  const { data: unviewedPostCount } = useGetUnviewedPostsCount({
    companyIds: combinedIds,
  });
  const { data: authorizedUser } = useGetMe();

  const isNoAccessToOtherCompanies =
    authorizedUser?.package === PACKET_ENUM.BASIC ||
    authorizedUser?.package === PACKET_ENUM.STANDARD;

  const isEmptyState = !myRelianceData?.length && !myOtherData?.length;
  return (
    <>
      {isDesktop() ? (
        <DashboardTopPortal>
          <CompaniesSummary />
        </DashboardTopPortal>
      ) : (
        <>
          <CompaniesSummary />
          <Button
            className="dashboard__top-button button--rounded  dashboard__top-button--add-company"
            onClick={() =>
              setModalManage({ type: "addCompany", company: null })
            }
            label="Dodaj spółkę  +"
            size="medium"
            styleType="primary"
          />
        </>
      )}
      {!authorizedUser?.package && !isEmptyState && (
        <Banner
          text={
            <p>
              Aby uzyskać dostęp do informacji rynkowych i powiadomień{" "}
              <b>konieczne jest wykupienie pakietu</b>
            </p>
          }
          buttonLabel="Sprawdź dostępne pakiety"
          buttonOnClick={() =>
            setModalManage({ type: "packet", company: null })
          }
          className={`companies__top-banner `}
        />
      )}

      {isEmptyState ? (
        <EmptyState
          className="companies-table__empty"
          svg={SVG_ENUM.INFO}
          text={
            <p>
              W tym miejscu pojawi się lista Twoich spółek zakupionych
              w&nbsp;Reliance. Aby dodać spółkę, kliknij przycisk
              <b>“Dodaj spółkę”</b> w&nbsp;prawym górnym rogu.
            </p>
          }
        />
      ) : (
        <>
          <h2 className="companies-table__title">
            Spółki wykupione w Reliance
          </h2>
          {myRelianceData?.length ? (
            <Table
              rowKey="id"
              className={`companies-table ${
                !authorizedUser?.package ? "companies-table--no-access" : ""
              }`}
              data={myRelianceData}
              onRow={(record: any) => ({
                onClick: (e) => onRowClick && onRowClick(record, e),
              })}
              columns={
                isDesktop()
                  ? desktopColumns({
                      setEditCompany,
                      unviewedPostCount,
                      setShowSellShares,
                    })
                  : mobileColumns({
                      setEditCompany,
                      unviewedPostCount,
                      setShowSellShares,
                    })
              }
            />
          ) : (
            <p className="companies-table__empty-message">
              W tym miejscu pojawi się lista Twoich spółek wykupionych w
              Reliance.
            </p>
          )}
          {myOtherData?.length ? (
            <>
              <h2 className="companies-table__title companies-table__title--other">
                Inne spółki
              </h2>

              {isNoAccessToOtherCompanies && (
                <Banner
                  text={
                    <p>
                      <b>Wykup wyższy pakiet</b>, aby odblokować dostęp do
                      informacji rynkowych na temat spółek wykupionych poza
                      Reliance.
                    </p>
                  }
                  buttonLabel="Zmiana pakietu"
                  buttonOnClick={() =>
                    setModalManage({ type: "packet", company: null })
                  }
                />
              )}
              <Table
                rowKey="id"
                data={myOtherData}
                className={`companies-table companies-table--other ${
                  isNoAccessToOtherCompanies || !authorizedUser?.package
                    ? "companies-table--no-access"
                    : ""
                }`}
                onRow={(record: any) => ({
                  onClick: (e) => onRowClick && onRowClick(record, e),
                })}
                columns={
                  isDesktop()
                    ? desktopColumns({
                        setEditCompany,
                        isOther: true,
                        setShowSellShares,
                        setShowDelete,
                        unviewedPostCount,
                      })
                    : mobileColumns({
                        setEditCompany,
                        setShowSellShares,
                        setShowDelete,
                        unviewedPostCount,
                      })
                }
              />
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default MyCompaniesTab;
