import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { CompanyMyType, CompanyOriginType } from "types/CompanyTypes";

interface GetMyCompaniesHookType {
  origin: CompanyOriginType["origin"];
  enabled?: boolean;
  archived?: boolean;
}

export default function useGetMyCompanies({
  origin,
  enabled = true,
  archived = false,
}: GetMyCompaniesHookType) {
  return useQuery({
    queryKey: ["companies/my", origin, archived],
    queryFn: async () => {
      return axiosInstance
        .get(`/companies/my?origin=${origin}&archived=${archived}`)
        .then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: CompanyMyType[]) => data,
    retry: false,
    enabled,
    staleTime: 1000 * 15,
  });
}
