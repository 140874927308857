import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { Button, Input, Switch, Warning } from "shared";
import * as Yup from "yup";
import { useUpdateInvoiceSettings } from "hooks/users";
import "./InvoiceMailing.scss";
import { useGetMe } from "hooks/auth";
import { VALIDATION_ENUM } from "enums";

const InvoiceMailing: React.FC = () => {
  const [emailConfirmed, setEmailConfirmed] = useState(true);
  const { mutate: updateInvoiceSettings } = useUpdateInvoiceSettings();
  const { data: authorizedUser } = useGetMe();
  const isSendInvoice = authorizedUser?.isSendInvoice;
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (!authorizedUser?.invoiceEmail) {
      setEmailConfirmed(false);
    }
  }, [authorizedUser?.email, authorizedUser?.invoiceEmail]);

  const validationSchema = Yup.object().shape({
    invoiceEmail: Yup.string()
      .required(VALIDATION_ENUM.REQUIRED)
      .email(VALIDATION_ENUM.WRONG_EMAIL),
  });

  const handleConfirmEmail = (values: { invoiceEmail: string }) => {
    updateInvoiceSettings({
      isSendInvoice: true,
      invoiceEmail: values.invoiceEmail,
    });
    setEmailConfirmed(true);
  };

  return (
    <div className="payments__option-wrapper invoice-mailing">
      <div className="payments__option">
        <div className="payments__option-header">Wysyłka faktur na maila</div>
        {authorizedUser && (
          <Switch
            state={authorizedUser.isSendInvoice}
            disabled={!emailConfirmed}
            onClick={() =>
              updateInvoiceSettings({
                isSendInvoice: !isSendInvoice,
              })
            }
          />
        )}

        <Formik
          initialValues={{
            invoiceEmail:
              authorizedUser?.invoiceEmail || authorizedUser?.email || "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleConfirmEmail}
        >
          {({ errors, touched, values }) => {
            setErrors(errors);
            return (
              <Form>
                <div className="payments__option-content">
                  <Field
                    type="invoiceEmail"
                    name="invoiceEmail"
                    label="Adres email do wysyłki faktur"
                    as={Input}
                    className={`invoice-mailing__input ${
                      emailConfirmed || values.invoiceEmail === ""
                        ? "invoice-mailing__input--confirmed"
                        : ""
                    }`}
                    onInputChange={() => setEmailConfirmed(false)}
                    hideValidation
                  />
                  <Button
                    type="submit"
                    label="Potwierdź"
                    className={`invoice-mailing__button ${
                      emailConfirmed || values.invoiceEmail === ""
                        ? "invoice-mailing__button--confirmed"
                        : ""
                    }`}
                    onClick={() => null}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {!authorizedUser?.invoiceEmail && !emailConfirmed && (
        <Warning
          className="invoice-mailing__warning warning--white"
          text={
            errors && errors.invoiceEmail ? (
              <p>
                {errors?.invoiceEmail === VALIDATION_ENUM.REQUIRED
                  ? "Pole z adresem Email jest wymagane "
                  : errors.invoiceEmail}
              </p>
            ) : (
              <p>
                Aby aktywować funkcję automatycznej wysyłki faktur{" "}
                <b>należy podać adres e-mail, na który będą wysyłane faktury</b>
                . Prosimy o uzupełnienie powyższego pola.
              </p>
            )
          }
        />
      )}
    </div>
  );
};

export default InvoiceMailing;
