import { VALIDATION_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useGetPhonePrefixes } from "hooks/phone";
import { useUpdateUser, useCreateAccountAsAdmin } from "hooks/users";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  FullscreenModal,
  Input,
  PhoneWithPrefix,
  SelectComponent,
  Warning,
} from "shared";
import { UserType } from "types/UsersTypes";
import * as Yup from "yup";
import "./edit-user.scss";
import { validatePhoneNumber } from "utils/validation/phoneValidation";

interface EditUserByAdminProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  user: UserType | null;
}

const EditUserByAdmin: React.FC<EditUserByAdminProps> = ({
  isOpen,
  onClose,
  onSuccess,
  user,
}) => {
  const { mutateAsync: updateUser } = useUpdateUser();
  const { mutateAsync: createUser } = useCreateAccountAsAdmin();
  const { data: phonePrefixes } = useGetPhonePrefixes();
  const formRef = useRef<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPhoneWarning, setShowPhoneWarning] = useState(false);
  const [showEmailWarning, setShowEmailWarning] = useState(false);

  const initialValues = {
    name: user?.name || "",
    email: user?.email || "" || null,
    phoneNumber: user?.phoneNumber || "",
    phonePrefix: user?.phonePrefixUuid || "",
  };

  useEffect(() => {
    if (phonePrefixes && formRef.current) {
      if (!!user) {
        const phonePrefix = phonePrefixes.find(
          (prefix) => prefix.value === user?.phonePrefixUuid
        );
        formRef.current.setFieldValue(
          "phonePrefix",
          phonePrefix || phonePrefixes[0]?.value
        );
      } else {
        const pl = phonePrefixes.find((prefix) => prefix.label === "+48");
        formRef.current.setFieldValue("phonePrefix", pl?.value);
      }
    }
  }, [phonePrefixes, user, isOpen]);

  const onSubmit = async (values: typeof initialValues) => {
    setIsSubmitting(true);
    setShowPhoneWarning(false);
    setShowEmailWarning(false);

    try {
      if (user) {
        await updateUser(
          //@ts-ignore
          { ...user, ...values, email: values.email || null },
          {
            onSuccess: () => {
              onSuccess && onSuccess();
              onClose();
            },
            onError: (error) => {
              handleErrors(error);
            },
          }
        );
      } else {
        // Create new user
        await createUser(
          {
            name: values.name,
            phoneNumber: values.phoneNumber,
            phonePrefixUuid: values.phonePrefix,
            email: values.email || undefined,
          },
          {
            onSuccess: () => {
              onSuccess && onSuccess();
              onClose();
            },
            onError: (error) => {
              handleErrors(error);
            },
          }
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleErrors = (error: any) => {
    switch (error?.data?.message) {
      case "Email exist":
        setShowEmailWarning(true);
        break;
      case "Phone number exist":
        setShowPhoneWarning(true);
        break;
      case "Phone number and email exist":
        setShowPhoneWarning(true);
        setShowEmailWarning(true);
        break;
      case "User with this email already exists":
        setShowEmailWarning(true);
        break;
      default:
        break;
    }
  };

  const validation = () =>
    Yup.object().shape({
      name: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .max(50)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
      email: Yup.string()
        .email(VALIDATION_ENUM.WRONG_EMAIL)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        )
        .nullable(),
      phoneNumber: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .min(9, VALIDATION_ENUM.MIN_9)
        .max(12, VALIDATION_ENUM.MAX_12)
        .test("is-digitals-only", VALIDATION_ENUM.NUMBER, (value) => {
          return /^\d+$/.test(value);
        })
        .test("phone-validation", VALIDATION_ENUM.NUMBER, function (value) {
          if (!/^\d+$/.test(value)) {
            return false;
          }

          const { phonePrefixUuid } = this.parent;

          if (value && phonePrefixUuid && phonePrefixes?.length) {
            const validation = validatePhoneNumber(
              value,
              phonePrefixUuid,
              phonePrefixes
            );

            if (!validation.isValid) {
              return this.createError({
                message: validation.error || VALIDATION_ENUM.NUMBER,
              });
            }
          }

          return true;
        }),
      phonePrefix: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    });

  return (
    <FullscreenModal isOpen={isOpen} onClose={onClose} className="edit-user">
      <h2 className="fullscreen-modal__header">
        {user ? "Edycja użytkownika" : "Dodawanie użytkownika"}
      </h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={validation()}
        enableReinitialize
        innerRef={formRef}
      >
        {({ errors, values, handleSubmit }) => (
          <Form className="m-width-100">
            <PhoneWithPrefix>
              <Field
                label="Telefon"
                name="phonePrefix"
                id="phonePrefix"
                as={SelectComponent}
                options={phonePrefixes}
              />
              <Field
                name="phoneNumber"
                type="text"
                id="phoneNumber"
                maxLength="12"
                as={Input}
                onInputChange={() => setShowPhoneWarning(false)}
              />
            </PhoneWithPrefix>
            <Warning
              className={`edit-user__warning ${
                showPhoneWarning ? "" : "edit-user__warning--hidden"
              }`}
              text={
                "Podany numer telefonu jest już zarejestrowany w naszym systemie. Proszę podać inny numer."
              }
            />

            <Field
              type="text"
              id="name"
              name="name"
              label={"Imię i nazwisko"}
              as={Input}
            />

            <>
              <Field
                type="text"
                id="email"
                name="email"
                label={"Email"}
                optional
                as={Input}
                onInputChange={() => setShowEmailWarning(false)}
              />
              <Warning
                className={`edit-user__warning ${
                  showEmailWarning ? "" : "edit-user__warning--hidden"
                }`}
                text={
                  "Podany adres email jest już zarejestrowany w naszym systemie. Proszę podać inny adres email."
                }
              />
            </>

            <div className="fullscreen-modal__buttons">
              <Button
                label="Anuluj"
                onClick={onClose}
                className="button--stroke button--rounded"
              />
              <Button
                label={user ? "Zapisz" : "Dodaj"}
                type="submit"
                onClick={() => null}
                className=" button--rounded button--black"
                isLoading={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </FullscreenModal>
  );
};

export default EditUserByAdmin;
