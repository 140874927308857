import { SVG_ENUM, VALIDATION_ENUM } from "enums";
import { EditAddressDataModal } from "features/users";
import { Field, Form, Formik } from "formik";
import useSendContactAsUserMessage from "hooks/contact/SendContactMessageAsUserHook";
import React, { useRef, useState } from "react";
import { Button, DropFileInput, SelectComponent, SVG, Textarea } from "shared";
import { UserType } from "types/UsersTypes";
import * as Yup from "yup";

interface ContactProps {
  user: UserType | undefined;
  handleGoBack: () => void;
}

const Contact: React.FC<ContactProps> = ({ user, handleGoBack }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const { mutateAsync: sendMessage } = useSendContactAsUserMessage();
  const formRef = useRef<any>(null);
  const onSubmit = (values: any) => {
    console.log(values);
    sendMessage(values, {
      onSuccess: () => {
        formRef.current.resetForm();
        setIsSubmitSuccess(true);
      },
    });
  };

  const initialValues = {
    topic: "",
    message: "",
    file: null,
  };

  const validation = () =>
    Yup.object().shape({
      topic: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      message: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    });

  return (
    <div className="profile__right-wrapper">
      <EditAddressDataModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        user={user || null}
      />
      <div className="profile__right-back" onClick={() => handleGoBack()}>
        <SVG type={SVG_ENUM.ARROW_LEFT} /> Wróć
      </div>
      <h2 className="profile__menu-header">
        <span className="profile__menu-icon profile__menu-icon--right">
          <SVG type={SVG_ENUM.USER} />
        </span>
        Kontakt i wsparcie
      </h2>

      {isSubmitSuccess ? (
        <div className="profile__success-message">
          <h3 className="profile__menu-subheader">
            Dziękujemy za kontakt! Twoja wiadomość została wysłana. Odpowiemy na
            nią tak szybko, jak to możliwe.
          </h3>
          <Button
            size="large"
            styleType="primary"
            label="Wyślij kolejną wiadomość"
            onClick={() => setIsSubmitSuccess(false)}
          />
        </div>
      ) : (
        <>
          <h3 className="profile__menu-subheader">
            Potrzebujesz pomocy lub masz pytania? Jesteśmy tu, aby Cię wesprzeć.
            Wybierz temat, opisz swoją sprawę i załącz plik, jeśli to potrzebne
            — odpowiemy tak szybko, jak to możliwe.
          </h3>

          <Formik
            initialValues={initialValues}
            onSubmit={(values: any) => onSubmit(values)}
            validationSchema={validation()}
            enableReinitialize
            innerRef={formRef}
          >
            {({ errors, values, handleSubmit, setFieldValue }) => {
              console.log(values);
              return (
                <Form className="profile__form" onSubmit={handleSubmit}>
                  <Field
                    label="Temat"
                    name="topic"
                    id="topic"
                    as={SelectComponent}
                    options={[
                      {
                        label:
                          "Pełnomocnictwo na walne zgromadzenie spółki, w której jestem akcjonariuszem",
                        value:
                          "Pełnomocnictwo na walne zgromadzenie spółki, w której jestem akcjonariuszem",
                      },
                      {
                        label: "Problemy techniczne",
                        value: "Problemy techniczne",
                      },
                      {
                        label: "Inny powód",
                        value: "Inny powód",
                      },
                    ]}
                  />

                  <Field
                    label={"Załącz plik"}
                    name="file"
                    id="file"
                    as={DropFileInput}
                    accept=".pdf,.doc,.docx,.jpg,.png"
                    optional
                  />
                  <Field
                    type="textarea"
                    id="message"
                    name="message"
                    label={"Treść wiadomości"}
                    component={Textarea}
                    errors={errors}
                    maxLength={1000}
                  />
                  <div className="profile__form-buttons">
                    <Button
                      size="large"
                      styleType="primary"
                      label="Wyślij"
                      type="submit"
                      onClick={() => {}}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </div>
  );
};

export default Contact;
