import React from "react";
import "./PaymentMethodStep.scss";
import { AddPaymentMethod } from "features/users";

interface Props {
  goToNextStep: () => void;
}

const PaymentMethodStep: React.FC<Props> = ({ goToNextStep }) => {
  return <AddPaymentMethod callback={() => goToNextStep()} />;
};

export default PaymentMethodStep;
