import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { Toast } from "shared";
import { TOAST_TYPES_ENUM } from "enums";
import { queryClient } from "App";
import { UpdatePostType } from "types/PostTypes";

export const useUpdatePost = (
  options?: UseMutationOptions<any, Error, UpdatePostType>
) => {
  return useMutation({
    ...options,
    mutationKey: ["updatePost"],
    mutationFn: async (data) => {
      const formData = new FormData();
      formData.append("title", `${data?.title}`);
      formData.append("status", `${data?.status}`);
      formData.append("content", `${data?.content}`);
      formData.append("description", `${data?.description}`);
      if (data.file) {
        formData.append("file", data.file);
      }

      formData.append("link", `${data.link}`);
      formData.append("source", `${data.source}`);
      formData.append("companyIds", JSON.stringify(data.companyIds));
      formData.append("categoryIds", JSON.stringify(data.categoryIds));
      formData.append("publishedAt", `${data.publishedAt}`);

      return axiosInstance
        .put(`/posts/${data.id}`, formData)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Trwa aktualizacja informacji rynkowej...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "update-post",
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Informacja rynkowa zaktualizowana pomyślnie.",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "update-post",
      });
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: "Błąd podczas aktualizacji informacji rynkowej.",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "update-post",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useUpdatePost;
