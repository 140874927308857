import React from "react";
import { FullscreenModal } from "shared";
import { AddPaymentMethod } from "features/users";

const AddPaymentMethodModal: React.FC<any> = ({ isOpen, onClose }) => {
  return (
    <FullscreenModal
      className={`fullscreen-modal--wide packet-modal packet-modal--PaymentMethodStep`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <AddPaymentMethod callback={() => onClose()} />
    </FullscreenModal>
  );
};

export default AddPaymentMethodModal;
