import { VALIDATION_ENUM } from "enums";
import * as Yup from "yup";

export const validation = () =>
  Yup.object().shape({
    companyIds: Yup.array()
      .min(1, VALIDATION_ENUM.REQUIRED)
      .required(VALIDATION_ENUM.REQUIRED),
    title: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    link: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    source: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    status: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    publishedAt: Yup.date().test(
      "is-required",
      VALIDATION_ENUM.REQUIRED,
      function (value) {
        if (this.parent.status === "published") {
          return !!value;
        } else {
          return true;
        }
      }
    ),
    content: Yup.mixed().required(VALIDATION_ENUM.REQUIRED),
  });
