import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { SettingsType } from "types/SettingsTypes";

export default function useGetSettings(id?: string) {
  return useQuery({
    queryKey: ["settings"],
    queryFn: async () => {
      return axiosInstance
        .get(`settings${id ? "?id=" + id : ""}`)
        .then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: SettingsType[]) => data,
    retry: false,
  });
}
