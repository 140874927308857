import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { CustomerType } from "types/CustomerTypes";

export function useGetCustomer(enabled: boolean = true) {
  return useQuery({
    queryKey: ["customers"],
    queryFn: async () => {
      try {
        const response = await axiosInstance.get("/customers");
        return response.data;
      } catch (firstError) {}
    },
    staleTime: 10000,
    select: (data: { data: CustomerType }) => data.data,
    retry: 0,
    enabled,
  });
}
