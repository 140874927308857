import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { AnalyzeType } from "types/AnalyzeTypes";
import { MetaType } from "types/UtilsTypes";

export default function useGetAnalyze({
  page,
  enabled = true,
  isAdmin,
  status,
}: {
  page: number;
  enabled?: boolean;
  isAdmin?: boolean;
  status?: string;
}) {
  return useQuery({
    queryKey: ["analyzes", page, status],
    queryFn: async () => {
      return axiosInstance
        .get(
          `/analyzes${!isAdmin ? "/me" : ""}?page=${page}${
            status && isAdmin ? `&status=${status}` : ""
          }`
        )
        .then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: { data: AnalyzeType[]; meta: MetaType }) => data,
    retry: false,
    enabled,
    staleTime: 1000 * 15,
  });
}
