import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { Toast } from "shared";
import { TOAST_TYPES_ENUM } from "enums";
import { AddUserType } from "types/UsersTypes";
import { queryClient } from "App";

export const useCreateAccountAsAdmin = (
  options?: UseMutationOptions<any, Error, AddUserType>
) => {
  return useMutation({
    ...options,
    mutationKey: ["addUser"],
    mutationFn: async ({ name, phonePrefixUuid, phoneNumber, email }) => {
      return axiosInstance
        .post("/users/as/admin", {
          name,
          phonePrefixUuid,
          phoneNumber,
          email,
        })
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Trwa dodawanie konta...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "add-user",
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Pomyślnie dodano konto.",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "add-user",
      });
      queryClient.prefetchQuery({ queryKey: ["users"] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
      return true;
    },
    onError: (error: any, variables, context) => {
      if (error.data.message === "Phone number exist") {
        Toast({
          message: "Podany numer telefonu jest już zarejestrowany.",
          type: TOAST_TYPES_ENUM.ERROR,
          id: "add-user",
        });
      } else {
        Toast({
          message: "Błąd podczas dodawania konta.",
          type: TOAST_TYPES_ENUM.ERROR,
          id: "add-user",
        });
      }
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
      return false;
    },
  });
};

export default useCreateAccountAsAdmin;
