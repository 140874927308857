import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { UserType } from "types/UsersTypes";
import { useLogout } from "hooks/auth/";
import { queryClient } from "App";

export function useGetMe() {
  const { mutate: logout } = useLogout({});
  return useQuery({
    queryKey: ["me"],
    queryFn: async () => {
      try {
        const response = await axiosInstance.get("/users/me");
        return response.data;
      } catch (firstError) {
        try {
          const response = await axiosInstance.get("/users/me");
          return response.data;
        } catch (secondError) {
          if (
            queryClient.getQueryData(["me"]) &&
            window.location.href.includes("dashboard")
          ) {
            logout();
          }
          throw secondError;
        }
      }
    },
    staleTime: 10000,
    select: (data: { user: UserType }) => data.user,
    retry: 0,
  });
}
