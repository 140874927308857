import React, { useState } from "react";
import "./add-payment-method.scss";
import { Button, Checkbox } from "shared";
import { useGetCustomer, useStoreCustomer } from "hooks/customers";
import {
  useStorePaymentMethod,
  // useGetPaymentMethods
} from "hooks/payments";
import { useActivateSubscription } from "hooks/subscriptions";
import { CustomerType } from "types/CustomerTypes";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useGetMe } from "hooks/auth";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { VALIDATION_ENUM } from "enums";
import moment from "moment";

interface Props {
  callback?: () => void;
}

const inputStyles = {
  base: {
    color: "#4a445d",
    fontSize: "14px",
    lineHeight: "48px",
    fontWeight: "400",
    fontFamily: "Source Sans Pro, sans-serif",
    backgroundColor: "#F6F6F6",
    border: "none",
    height: "48px",
    spacingGridRow: "0",
    spacingGridColumn: "0",
    display: "flex",
    padding: "5px 10px 5px 20px",
    "::placeholder": {
      color: "#808080",
    },
  },
  invalid: {
    color: "#e20d0d",
    iconColor: "#e20d0d",
  },
};

const AddPaymentMethod: React.FC<Props> = ({ callback }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { mutateAsync: storeCustomer } = useStoreCustomer();
  const { mutateAsync: addPaymentMethod } = useStorePaymentMethod();
  const { mutateAsync: activateSubscription } = useActivateSubscription();
  const { data: authorizedUser } = useGetMe();
  const { data: customerData } = useGetCustomer();
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const { data: paymentMethods } = useGetPaymentMethods();
  const endOfPaymentCycle = moment().add(1, "month").startOf("month");

  const handleSubmit = async () => {
    if (sessionStorage.getItem("submitting")) return;
    sessionStorage.setItem("submitting", "true");
    setIsSubmitting(true);

    try {
      // @ts-ignore
      let customer: CustomerType | undefined;
      if (authorizedUser?.customerId) {
        customer = customerData;
      } else {
        customer = await storeCustomer();
      }
      console.log(customer);
      // @ts-ignore
      const test = elements.getElement(CardNumberElement);
      // @ts-ignore
      const { token, error } = await stripe.createToken(test);
      if (error) throw error;

      const card =
        customer &&
        (await addPaymentMethod({ stripePmToken: { id: token.id } }));

      card &&
        !customer?.subscriptionUid &&
        (await activateSubscription({
          payment_method_uid: card.id,
          //@ts-ignore
          selectedPackage: sessionStorage.getItem("packet") || undefined,
        }));
      sessionStorage.removeItem("packet");
      sessionStorage.removeItem("submitting");
      callback && callback();
    } catch (error) {
      console.error("Payment error:", error);
    } finally {
      setIsSubmitting(false);
      sessionStorage.removeItem("submitting");
    }
  };

  const validation = () => {
    return Yup.object().shape({
      acceptance: Yup.boolean().oneOf([true], VALIDATION_ENUM.REQUIRED),
      cardNumber: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      cardExpiry: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      cardCvc: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    });
  };

  return (
    <>
      <h2 className="fullscreen-modal__header packet-modal__header">
        Metoda płatności
      </h2>
      <div className="add-payment-method">
        <p className="login-wrapper__subtitle">
          Podłącz swoją kartę płatniczą Lorem ipsum dolor sit amet consectetur.
          Feugiat at ultrices mattis tortor
        </p>
        <Formik
          initialValues={{ acceptance: false }}
          onSubmit={(values: any) => handleSubmit()}
          validationSchema={validation()}
          enableReinitialize
        >
          {({ errors, values, handleSubmit, setFieldValue }) => {
            return (
              <Form>
                <div className="stripe-fields">
                  <div className="add-payment-method__input-group">
                    <label className="input__label">
                      Numer karty
                      <CardNumberElement
                        options={{
                          style: inputStyles,
                          placeholder: "",
                          disableLink: true,
                        }}
                        onChange={(e) => {
                          if (e.empty) {
                            setFieldValue("cardNumber", "");
                          } else {
                            setFieldValue("cardNumber", "true");
                          }
                        }}
                      />
                      {
                        //@ts-ignore
                        errors.cardNumber && (
                          <div className="input__error">
                            {/* @ts-ignore */}
                            {errors?.cardNumber}
                          </div>
                        )
                      }
                    </label>
                  </div>
                  <div className="add-payment-method__input-group">
                    <label className="input__label">
                      Data ważności
                      <CardExpiryElement
                        options={{ style: inputStyles, placeholder: "" }}
                        onChange={(e) => {
                          if (e.empty) {
                            setFieldValue("cardExpiry", "");
                          } else {
                            setFieldValue("cardExpiry", "true");
                          }
                        }}
                      />
                      {
                        //@ts-ignore
                        errors.cardExpiry && (
                          <div className="input__error">
                            {/* @ts-ignore */}
                            {errors?.cardExpiry}
                          </div>
                        )
                      }
                    </label>
                    <label className="input__label">
                      CVV
                      <CardCvcElement
                        options={{ style: inputStyles, placeholder: "" }}
                        onChange={(e) => {
                          if (e.empty) {
                            setFieldValue("cardCvc", "");
                          } else {
                            setFieldValue("cardCvc", "true");
                          }
                        }}
                      />
                      {
                        //@ts-ignore
                        errors.cardCvc && (
                          //@ts-ignore
                          <div className="input__error">{errors?.cardCvc}</div>
                        )
                      }
                    </label>
                  </div>
                  <Field
                    id="acceptance"
                    name="acceptance"
                    wrapperClassName="login-wrapper__acceptance checkbox__modal"
                    label="Lorem ipsum dolor sit amet consectetur. Feugiat at ultrices mattis tortor. Lorem ipsum dolor sit amet consectetur."
                    as={Checkbox}
                    errors={errors}
                  />
                </div>

                {!customerData?.subscriptionUid && (
                  <div className="add-payment-method__info">
                    Możesz zrezygnować z subskrybcji w dowolnym momenciem nawet
                    przed końcem okresu próbnego.
                    <br />
                    <br />
                    Pierwsza opłata zostanie pobrana{" "}
                    <b>{endOfPaymentCycle.format("DD MMMM YYYY")}</b>.
                  </div>
                )}

                <div className="fullscreen-modal__buttons">
                  <Button
                    label="Dalej"
                    className="button--black button--rounded"
                    onClick={() => handleSubmit()}
                    isLoading={isSubmitting}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddPaymentMethod;
