import React from "react";
import { PACKET_ENUM } from "../../enums/PacketsEnum";
import "./PacketBadge.scss";
import { isDesktop } from "utils";

interface PacketBadgeProps {
  packetType: PACKET_ENUM;
}

const PacketBadge: React.FC<PacketBadgeProps> = ({ packetType }) => {
  if (!packetType) {
    return null;
  }
  const getBadgeClass = (type: PACKET_ENUM) => {
    switch (type) {
      case PACKET_ENUM.BASIC:
        return "badge-basic";
      case PACKET_ENUM.STANDARD:
        return "badge-standard";
      case PACKET_ENUM.PREMIUM:
        return "badge-premium";
      case PACKET_ENUM.PRO:
        return "badge-pro";
      default:
        return "";
    }
  };

  return (
    <span className={`packet-badge ${getBadgeClass(packetType)}`}>
      {isDesktop()
        ? packetType.toUpperCase()
        : packetType.toUpperCase().charAt(0)}
    </span>
  );
};

export default PacketBadge;
