import { NavLink } from "react-router-dom";
import { ROUTE_ENUM, SVG_ENUM, ROLES_ENUM } from "enums";
import "./MobileMenu.scss";
import { UserType } from "types/UsersTypes";
import SVG from "shared/SVG";
import { clearSessionStorage } from "utils";

interface MobileMenuType {
  user: UserType | undefined;
}

const MobileMenu = ({ user }: MobileMenuType) => {
  const isAdmin = user?.role === ROLES_ENUM.ADMIN;

  const handleOnClick = () => {
    clearSessionStorage();
  };

  return (
    <div className="mobile-menu">
      {isAdmin ? (
        <>
          <NavLink
            to={ROUTE_ENUM.COMPANIES}
            className="mobile-menu-item"
            onClick={handleOnClick}
          >
            <div className="mobile-menu-item__svg-wrapper">
              <SVG type={SVG_ENUM.LIST_UNORDERED} />
            </div>
            <div className="mobile-menu-item__name">Spółki</div>
          </NavLink>
          <NavLink
            to={ROUTE_ENUM.MARKET_INFORMATION}
            className="mobile-menu-item"
            onClick={handleOnClick}
          >
            <div className="mobile-menu-item__svg-wrapper">
              <SVG type={SVG_ENUM.ANNOUCEMENT} />
            </div>
            <div className="mobile-menu-item__name">Informacje</div>
          </NavLink>
          <NavLink
            to={`${ROUTE_ENUM.ANALYSIS}`}
            className="mobile-menu-item"
            onClick={handleOnClick}
          >
            <div className="mobile-menu-item__svg-wrapper">
              <SVG type={SVG_ENUM.PRESENTATION} />
            </div>
            <div className="mobile-menu-item__name">Analizy</div>
          </NavLink>
          <NavLink
            to={`${ROUTE_ENUM.USERS}`}
            className="mobile-menu-item"
            onClick={handleOnClick}
          >
            <div className="mobile-menu-item__svg-wrapper">
              <SVG type={SVG_ENUM.USER_GROUP} />
            </div>
            <div className="mobile-menu-item__name">Użytkownicy</div>
          </NavLink>
        </>
      ) : (
        <>
          <NavLink
            to={ROUTE_ENUM.COMPANIES}
            className="mobile-menu-item"
            onClick={handleOnClick}
          >
            <div className="mobile-menu-item__svg-wrapper">
              <SVG type={SVG_ENUM.LIST_UNORDERED} />
            </div>
            <div className="mobile-menu-item__name">Spółki</div>
          </NavLink>
          <NavLink
            to={ROUTE_ENUM.MARKET_INFORMATION}
            className="mobile-menu-item"
            onClick={handleOnClick}
          >
            <div className="mobile-menu-item__svg-wrapper">
              <SVG type={SVG_ENUM.ANNOUCEMENT} />
            </div>
            <div className="mobile-menu-item__name">Informacje</div>
          </NavLink>
          <NavLink
            to={`${ROUTE_ENUM.ANALYSIS}`}
            className="mobile-menu-item"
            onClick={handleOnClick}
          >
            <div className="mobile-menu-item__svg-wrapper">
              <SVG type={SVG_ENUM.PRESENTATION} />
            </div>
            <div className="mobile-menu-item__name">Analizy</div>
          </NavLink>
          <NavLink
            to={`${ROUTE_ENUM.PROFILE}`}
            className="mobile-menu-item"
            onClick={handleOnClick}
          >
            <div className="mobile-menu-item__svg-wrapper">
              <SVG type={SVG_ENUM.USER} />
            </div>
            <div className="mobile-menu-item__name">Profil</div>
          </NavLink>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://reliance.pro/inwestycje/#konsultacja-inwestycje"
            className="mobile-menu-item"
          >
            <div className="mobile-menu-item__svg-wrapper">
              <SVG type={SVG_ENUM.CALENDAR} />
            </div>
            <div className="mobile-menu-item__name">Spotkanie</div>
          </a>
        </>
      )}
    </div>
  );
};

export default MobileMenu;
