// EmptyModal.tsx
import React, { FC, ReactNode, useEffect } from "react";
import { Transition } from "react-transition-group";
import "./EmptyModal.scss";
import { SVG_ENUM } from "enums";
import { createPortal } from "react-dom";
import SVG from "shared/SVG";

interface EmptyModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  topLeftLabel?: string;
  topLeftOnClick?: () => void;
  title: string;
}

const EmptyModal: FC<EmptyModalProps> = ({
  isOpen,
  onClose,
  children,
  className = "",
  topLeftLabel = "",
  topLeftOnClick = () => {},
  title,
}) => {
  const duration = 300;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
  };

  const transitionStyles: { [key: string]: React.CSSProperties } = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  const html = document.querySelector("html");
  useEffect(() => {
    if (isOpen) {
      html?.classList.add("no-scroll");
    } else {
      html?.classList.remove("no-scroll");
    }
  }, [isOpen, html]);

  return createPortal(
    <Transition in={isOpen} timeout={duration} unmountOnExit>
      {(state) => (
        <div
          className="empty-modal-overlay"
          // onClick={onClose}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <div className="empty-modal__top">
            <span className="empty-modal__top-left" onClick={topLeftOnClick}>
              {topLeftLabel}{" "}
            </span>
            <h2 className="empty-modal__top-title">{title}</h2>
            <span className="empty-modal__close" onClick={() => onClose()}>
              Zamknij
              <SVG type={SVG_ENUM.CLOSE} />
            </span>
          </div>

          <div
            className={`empty-modal ${className}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="empty-modal__content">{children}</div>
          </div>
        </div>
      )}
    </Transition>,
    document.querySelector(".App") ?? document.body
  );
};

export default EmptyModal;
