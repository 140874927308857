import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
// import { ClientType } from "types/ClientType";

export default function useGetPaymentMethods(enabled: boolean = true) {
  return useQuery({
    queryKey: ["paymentMethods"],
    queryFn: async () => {
      return axiosInstance.get("/payment-methods").then((res) => res.data);
    },
    initialData: [],
    select: (data: any[]) => data,
    retry: false,
    enabled,
    // staleTime: 1000 * 60 * 5,
  });
}
