import "./Swich.scss";

type P = {
  onClick: () => void;
  state: boolean;
  disabled?: boolean;
  classNames?: string;
  key?: string;
  dataTip?: string;
  labelClassNames?: string;
  label?: string;
  withoutLabel?: boolean;
};

const Switch = ({
  state,
  disabled,
  onClick,
  classNames,
  labelClassNames,
  dataTip,
  withoutLabel,
  label,
}: P) => {
  return (
    <>
      <div className="switch">
        <div className="wrapper">
          {withoutLabel ? null : <p className={labelClassNames}>{label}</p>}
        </div>
        <label
          className={`checkbox-wrapper ${classNames} ${
            disabled ? "disabled" : ""
          }`}
        >
          {state ? <input type="checkbox" checked disabled={disabled} /> : ""}
          <small
            onClick={() => {
              !disabled && onClick();
            }}
          ></small>
        </label>
      </div>
    </>
  );
};

export default Switch;
