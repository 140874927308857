import { PACKET_ENUM, VALIDATION_ENUM } from "enums";
import React, { useEffect, useState } from "react";
import "./ChoosePacketStep.scss";
import SinglePacket from "./SinglePacket";
import { Button, Input } from "shared";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { ParentType } from "../../PacketModal";
import { useChangePacket } from "hooks/users";
import { useGetCustomer } from "hooks/customers";
import {
  useActivateSubscription,
  useGetSubscription,
} from "hooks/subscriptions";
import { useGetPaymentMethods } from "hooks/payments";
import { useGetMe } from "hooks/auth";

interface Props {
  onClose: () => void;
  parent: ParentType;
  goNextStep: () => void;
  setStepOptions?: any;
  stepOptions?: any;
  setFormData?: ({ packet }: any) => void;
}

const ChoosePacketStep: React.FC<Props> = ({
  onClose,
  parent,
  goNextStep,
  setStepOptions,
  stepOptions,
  setFormData,
}) => {
  const [packet, setPacket] = useState<PACKET_ENUM | null>(
    stepOptions?.ChoosePacketStep?.data || null
  );
  const [selectedPacket, setSelectedPacket] = useState<PACKET_ENUM | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCouponSubmitting, setIsCouponSubmitting] = useState(false);
  const { mutateAsync: activateSubscription } = useActivateSubscription();
  const { data: authorizedUser } = useGetMe();
  const { data: customerData } = useGetCustomer(!!authorizedUser);
  const { mutateAsync: changePacket } = useChangePacket();
  const { data: paymentMethods } = useGetPaymentMethods(!!authorizedUser);
  const { data: subscription } = useGetSubscription(!!authorizedUser);
  const card = paymentMethods?.find((paymentMethod) => paymentMethod.default);

  const handlePacketChange = async (packet: PACKET_ENUM) => {
    setIsSubmitting(true);
    try {
      if (setStepOptions)
        setStepOptions({ ...stepOptions, ChoosePacketStep: { data: packet } });
      sessionStorage.setItem("packet", packet);
      // setPacket(packet);

      if (parent === "homepage") {
        setFormData && setFormData({ packet });
        goNextStep();
      }

      if (parent === "payment") {
        if (!card) {
          sessionStorage.setItem("packet", packet);
          goNextStep();
          return;
        }

        if (
          (!customerData?.subscriptionUid || subscription?.canceled_at) &&
          card
        )
          await activateSubscription({
            payment_method_uid: card.id,
            selectedPackage: packet,
          });
        await changePacket(packet, { onSuccess: () => onClose() });
      }
    } finally {
      console.log();
      setIsSubmitting(false);
      // setIsSubmitting(false);
    }
  };

  useEffect(() => {
    setPacket(authorizedUser?.package ?? null);
  }, [authorizedUser?.package]);

  const isFromPayment = parent === "payment";
  const isFromHomepage = parent === "homepage";

  const validationSchema = Yup.object().shape({
    coupon: Yup.string().required(VALIDATION_ENUM.REQUIRED),
  });

  const handleCouponChange = async (values: any) => {
    setIsCouponSubmitting(true);
    try {
      console.log(values);
      // Implement coupon validation logic here
    } finally {
      setIsCouponSubmitting(false);
    }
  };

  return (
    <>
      <h2 className="fullscreen-modal__header packet-modal__header">
        Wybierz pakiet, który Cię interesuje
      </h2>
      <div className="choose-packet-step__wrapper">
        {isFromHomepage ? (
          <Formik
            initialValues={{ coupon: "" }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values) => handleCouponChange(values)}
          >
            {({ handleSubmit, errors, isSubmitting, values }) => (
              <Form className="choose-packet-step__coupon">
                <Field
                  type="text"
                  id="coupon"
                  name="coupon"
                  label={
                    <p className="choose-packet-step__coupon-label">
                      Masz kod rabatowy? <b>Wpisz go tutaj ↓</b>
                    </p>
                  }
                  as={Input}
                />
                <Button
                  className="button--rounded button--black button--stroke choose-packet-step__button"
                  label="Zastosuj"
                  onClick={handleSubmit}
                  isLoading={isCouponSubmitting}
                />
              </Form>
            )}
          </Formik>
        ) : null}
        <div className="choose-packet-step">
          <SinglePacket
            packetName={PACKET_ENUM.BASIC}
            currentPacket={packet}
            selectedPacket={selectedPacket}
            setSelectedPacket={setSelectedPacket}
            parent={parent}
          />
          <SinglePacket
            packetName={PACKET_ENUM.STANDARD}
            currentPacket={packet}
            selectedPacket={selectedPacket}
            setSelectedPacket={setSelectedPacket}
            parent={parent}
          />
          <SinglePacket
            packetName={PACKET_ENUM.PREMIUM}
            currentPacket={packet}
            selectedPacket={selectedPacket}
            setSelectedPacket={setSelectedPacket}
            parent={parent}
          />
          <SinglePacket
            packetName={PACKET_ENUM.PRO}
            currentPacket={packet}
            selectedPacket={selectedPacket}
            setSelectedPacket={setSelectedPacket}
            parent={parent}
          />
        </div>
        <div
          className={`choose-packet-step__submit-buttons ${
            !!selectedPacket ? "choose-packet-step__submit-buttons--show" : ""
          }
          choose-packet-step__submit-buttons--right
          choose-packet-step__submit-buttons--${parent} 
          `}
        >
          {isFromPayment ? (
            <>
              <Button
                className="button--rounded button--black button--stroke choose-packet-step__button"
                label="Zapisz zmiany"
                onClick={() =>
                  selectedPacket && handlePacketChange(selectedPacket)
                }
                isLoading={isSubmitting}
              />
            </>
          ) : null}
          {isFromHomepage ? (
            <Button
              className="button--rounded button--black button--stroke choose-packet-step__button"
              label="Dalej"
              onClick={() =>
                selectedPacket && handlePacketChange(selectedPacket)
              }
              isLoading={isSubmitting}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ChoosePacketStep;
