import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { queryClient } from "App";
import axiosInstance from "axiosConfig";
import { UpdateSettingType } from "types/SettingsTypes";

export const useUpdateSettings = (
  options?: UseMutationOptions<any, Error, UpdateSettingType>
) => {
  return useMutation({
    mutationKey: ["updateSettings"],
    mutationFn: async (values: UpdateSettingType) => {
      return axiosInstance.put(`settings`, values).then((res) => res.data);
    },
    onMutate: (variables) => {
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.prefetchQuery({ queryKey: ["settings"] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },

    onError: (error, variables, context) => {
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
