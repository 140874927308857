import { PostType } from "types/PostTypes";
import "./PostItem.scss";
import moment from "moment";
import { PostStatusBadge, SVG, Warning } from "shared";
import { ROLES_ENUM, ROUTE_ENUM, SVG_ENUM } from "enums";
import { PostModalManage } from "screens/marketInfoPage/MarketInfoPage";
import { formatDate, isDesktop } from "utils";
import { useGetMe } from "hooks/auth";
import { useNavigate } from "react-router";
import Skeleton from "react-loading-skeleton";
import { useState } from "react";

interface PostItemType {
  data: PostType;
  setModalManage: React.Dispatch<React.SetStateAction<PostModalManage>>;
  companyId?: number | null;
  isLoading?: boolean;
}

const PostItem = ({
  data,
  setModalManage,
  companyId,
  isLoading = false,
}: PostItemType) => {
  const [imageLoading, setImageLoading] = useState(true);
  const publishedAt = moment(data?.publishedAt);
  const createdAt = moment(data?.createdAt);
  const company = data?.companies?.length
    ? data.companies.find((c) => c.id === companyId) || data.companies[0]
    : null;
  const { data: authorizedUser } = useGetMe();
  const isUser = authorizedUser?.role === ROLES_ENUM.USER;
  const navigate = useNavigate();

  const isPlanned =
    data?.status === "published" && publishedAt.isAfter(moment());

  const isNotificationError =
    data?.status === "published" &&
    !isPlanned &&
    !data?.notificationsSendAt &&
    moment().diff(createdAt, "minutes") >= 15 &&
    !isUser;

  const handleClick = (e: any) => {
    if (e.target.classList.contains("post-item__badge")) {
      e.stopPropagation();
    } else {
      navigate(ROUTE_ENUM.POST.replace(":id", data.id.toString()));
    }
  };

  if (isLoading) {
    return (
      <article className={`post-item ${isUser ? "post-item__user" : ""}`}>
        <div className="post-item__wrapper">
          <div className="post-item__image">
            <Skeleton height={188} />
          </div>
          <div className="post-item__content">
            {isUser ? (
              <>
                <Skeleton
                  width={100}
                  height={28}
                  className="post-item__company-user"
                />
                <Skeleton
                  width={300}
                  height={24}
                  className="post-item__title-user"
                />
                <div className="post-item__link">
                  Źródło: <Skeleton width={150} height={20} />
                </div>
                <Skeleton count={2} className="post-item__content-data" />
                <Skeleton width={100} className="post-item__date" />
              </>
            ) : (
              <>
                <div className="post-item__top">
                  <Skeleton width={150} className="post-item__date" />
                  {isDesktop() && (
                    <div className="post-item__right">
                      <Skeleton width={100} height={28} />
                      <div className="post-item__badges">
                        <Skeleton
                          circle
                          width={28}
                          height={28}
                          count={3}
                          inline
                        />
                      </div>
                    </div>
                  )}
                </div>
                <Skeleton width={100} className="post-item__company" />
                <Skeleton
                  width={300}
                  height={24}
                  className="post-item__title"
                />
              </>
            )}
          </div>
        </div>
      </article>
    );
  }

  return (
    <article
      className={`post-item ${data.isViewed ? "" : "post-item--not-viewed"} ${
        isUser ? "post-item__user" : ""
      }`}
      key={data.uuid}
      onClick={(e) => isUser && handleClick(e)}
    >
      <div className="post-item__wrapper">
        {!isDesktop() && !isUser ? (
          <div className="post-item__right">
            <PostStatusBadge status={data.status} isPlanned={isPlanned} />
            <div className="post-item__badges">
              <span
                className="post-item__badge post-item__badge--delete"
                onClick={() =>
                  setModalManage({ type: "deletePost", post: data })
                }
              >
                <SVG type={SVG_ENUM.TRASH} />
              </span>
              <span
                className="post-item__badge post-item__badge--edit"
                onClick={() => setModalManage({ type: "addPost", post: data })}
              >
                <SVG type={SVG_ENUM.EDIT} />
              </span>
            </div>
          </div>
        ) : null}
        <div className="post-item__image">
          {imageLoading && <Skeleton height={188} />}
          {data ? (
            data?.file?.url ? (
              <img
                src={data.file?.url}
                alt={data.title}
                onLoad={() => setImageLoading(false)}
                onError={(e: any) => {
                  e.target.src = require("assets/images/PostPlaceholder.webp");
                  setImageLoading(false);
                }}
              />
            ) : (
              <img
                src={require("assets/images/PostPlaceholder.webp")}
                alt={data.title}
                onLoad={() => setImageLoading(false)}
              />
            )
          ) : null}
        </div>
        <div className="post-item__content">
          {isUser ? (
            <>
              <p className="post-item__company-user">{company?.name}</p>
              <h2 className="post-item__title-user">{data?.title}</h2>
              <div className="post-item__link">
                Źródło:{" "}
                <a href={data.link} target="_blank" rel="noreferrer">
                  <SVG type={SVG_ENUM.LINK} />
                  {data.source}
                </a>
              </div>
              <p className="post-item__content-data">{data.description}</p>
              <p className="post-item__date">
                {data.publishedAt && formatDate(data.publishedAt)}
              </p>
            </>
          ) : (
            <>
              <div className="post-item__top">
                <span className="post-item__date">
                  {publishedAt.format(`DD/MM/yyyy`)} godz.{" "}
                  {publishedAt.format(`HH:mm`)}
                </span>
                {isDesktop() && !isUser ? (
                  <div className="post-item__right">
                    <PostStatusBadge
                      status={data.status}
                      isPlanned={isPlanned}
                    />
                    <div className="post-item__badges">
                      <span
                        className="post-item__badge post-item__badge--delete"
                        onClick={() =>
                          setModalManage({ type: "deletePost", post: data })
                        }
                      >
                        <SVG type={SVG_ENUM.TRASH} />
                      </span>
                      <span
                        className="post-item__badge post-item__badge--edit"
                        onClick={() =>
                          setModalManage({ type: "addPost", post: data })
                        }
                      >
                        <SVG type={SVG_ENUM.EDIT} />
                      </span>
                      <span
                        className="post-item__badge post-item__badge--preview"
                        onClick={() =>
                          navigate(
                            ROUTE_ENUM.POST.replace(":id", data.id.toString())
                          )
                        }
                      >
                        <SVG type={SVG_ENUM.EYE} />
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
              <p className="post-item__company">{company?.name}</p>
              <h2 className="post-item__title">{data?.title}</h2>
            </>
          )}
        </div>
      </div>
      {isNotificationError && (
        <Warning
          text={`Wystąpił błąd, nie udało się wysłać notyfikacji o tym poście`}
        />
      )}
    </article>
  );
};

export default PostItem;
