import { SVG_ENUM } from "enums";
import { SVG } from "shared";
import "./Button.scss";

interface ButtonProps {
  label: string;
  svg?: SVG_ENUM;
  onClick: () => void;
  className?: string;
  type?: "button" | "submit" | "reset";
  svgOnly?: boolean;
  styleType?: "primary" | "strokeBlack" | "secondary" | "strokeGray";
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  label,
  svg,
  onClick,
  className = "",
  type = "button",
  svgOnly,
  styleType,
  disabled,
  size,
  isLoading = false,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled || isLoading}
      className={`button ${className} ${
        svgOnly ? "button--svg-only" : ""
      } button--${styleType} button--${size}
       ${size ? "" : "button--wide"}
       ${isLoading ? "button--loading" : ""}
        `}
    >
      {isLoading ? (
        <span className="button__loader"></span>
      ) : (
        <>
          {svg && <SVG type={svg} />}
          {svgOnly ? null : label}
        </>
      )}
    </button>
  );
};

export default Button;
