import { ContextMenu, SVG } from "shared";
import { SVG_ENUM } from "enums";

import { CompanyMyType } from "types/CompanyTypes";
import moment from "moment";
import { getCurrencyFromUuid, getIndustryFromUuid } from "utils";
import { MarketInfoSpeaker } from "features/marketInfo";
import { UnviewedPostCountType } from "types/PostTypes";

const items = ({
  setEditCompany,
  setShowSellShares,
  setShowDelete,
}: {
  setEditCompany: (company: CompanyMyType) => void;
  setShowSellShares?: (company: CompanyMyType) => void;
  setShowDelete?: (company: CompanyMyType) => void;
}) => {
  return (company: CompanyMyType) => {
    const actions = [];

    actions.push({
      name: "Zaznacz transakcje",
      action: () => setShowSellShares && setShowSellShares(company),
      svg: SVG_ENUM.COINS_HAND,
    });
    if (company.origin === "other") {
      actions.unshift({
        name: "Edytuj",
        action: () => setEditCompany(company),
        svg: SVG_ENUM.EDIT,
      });

      actions.push({
        name: "Usuń",
        action: () => setShowDelete && setShowDelete(company),
        svg: SVG_ENUM.TRASH,
      });
    }

    return actions;
  };
};

export const mobileColumns = ({
  setEditCompany,
  setShowSellShares,
  setShowDelete,
  unviewedPostCount,
}: {
  setEditCompany: (company: CompanyMyType) => void;
  setShowDelete?: (company: CompanyMyType) => void;
  setShowSellShares?: (company: CompanyMyType) => void;
  unviewedPostCount: UnviewedPostCountType | undefined;
}) => [
  {
    title: "",
    key: "index",
    width: 40,
    render: (value: any, record: CompanyMyType, index: number) => (
      <div
        data-tooltip-id="company-tooltip"
        data-tooltip-content={record.description}
        className="companies-table__info"
      >
        <SVG type={SVG_ENUM.INFO} />
      </div>
    ),
  },
  {
    dataIndex: "name",
    key: "name",
    width: 180,
  },
  {
    title: "",
    dataIndex: "",
    key: "status",
    width: 40,
    render: (value: any, record: CompanyMyType) => (
      <MarketInfoSpeaker
        id={record.id}
        postsCount={unviewedPostCount?.[record.id] || 0}
        origin={record.origin}
      />
    ),
  },

  {
    width: 40,
    render: (item: CompanyMyType) => (
      <ContextMenu
        data={item}
        items={items({
          setEditCompany,
          setShowSellShares,
          setShowDelete,
        })(item)}
      >
        <SVG type={SVG_ENUM.DOTS} />
      </ContextMenu>
    ),
  },
];

export const desktopColumns = ({
  setEditCompany,
  isOther = false,
  setShowSellShares,
  setShowDelete,
  unviewedPostCount,
}: {
  setEditCompany: (company: CompanyMyType) => void;
  setShowDelete?: (company: CompanyMyType) => void;
  isOther?: boolean;
  setShowSellShares?: (company: CompanyMyType) => void;
  unviewedPostCount: UnviewedPostCountType | undefined;
}) => {
  const columns = [
    {
      key: "index",
      width: 40,
      render: (value: any, record: CompanyMyType, index: number) => (
        <div
          data-tooltip-id="company-tooltip"
          data-tooltip-content={record.description}
          className="companies-table__info"
        >
          <SVG type={SVG_ENUM.INFO} />
        </div>
      ),
    },
    {
      title: "Nazwa spółki",
      dataIndex: "name",
      key: "name",
      width: "250px",
      minWidth: 250,
    },
    {
      title: "Branża",
      width: "130px",
      render: (value: any, record: CompanyMyType, index: number) => {
        return `${getIndustryFromUuid(record.industryUuid)}`;
      },
    },
    {
      title: "Wartość udziałów",
      width: 130,
      render: (value: any, record: CompanyMyType, index: number) =>
        record.sharesValue
          ? `${record.sharesValue} ${getCurrencyFromUuid(record.currencyUuid)}`
          : "-",
    },
    {
      title: "Waluta",
      width: 60,
      render: (value: any, record: CompanyMyType, index: number) =>
        `${getCurrencyFromUuid(record.currencyUuid)}`,
    },
    {
      title: isOther ? null : "Wycena spółki",
      width: 120,
      render: (value: any, record: CompanyMyType, index: number) =>
        isOther
          ? null
          : `${record.sharePrice} ${getCurrencyFromUuid(record.currencyUuid)}`,
    },
    {
      title: isOther ? null : "Data publikacji na giełdzie",
      width: 170,
      render: (value: any, record: CompanyMyType, index: number) =>
        record.publicationDate
          ? `${moment(record.publicationDate, "DD-MM-YYYY").format(
              "DD/MM/YYYY"
            )}r.`
          : "",
    },

    {
      title: "",
      dataIndex: "",
      key: "status",
      width: 40,
      render: (value: any, record: CompanyMyType) => (
        <MarketInfoSpeaker
          id={record.id}
          postsCount={unviewedPostCount?.[record.id] || 0}
          origin={record.origin}
        />
      ),
    },
    {
      width: 20,
      render: (item: CompanyMyType) => (
        <ContextMenu
          data={item}
          items={items({
            setEditCompany,
            setShowSellShares,
            setShowDelete,
          })(item)}
        >
          <SVG type={SVG_ENUM.DOTS} />
        </ContextMenu>
      ),
    },
  ];

  return columns;
};
