import { PACKET_ENUM, SVG_ENUM } from "enums";
import { PacketModal } from "features/users";
import { useGetMe } from "hooks/auth";
import { useState } from "react";
import { Button } from "shared";
import SVG from "shared/SVG";

interface CommunicationBannerProps {}

const CommunicationBanner: React.FC<CommunicationBannerProps> = () => {
  const [isPacketModalOpen, setIsPacketModalOpen] = useState(false);
  const { data: authorizedUser } = useGetMe();
  return (
    <>
      <PacketModal
        isOpen={isPacketModalOpen}
        onClose={() => setIsPacketModalOpen(false)}
        parent="payment"
      />
      <div className={`communication__banner`}>
        <div className="communication__banner-svg">
          <SVG type={SVG_ENUM.LOCK} />
        </div>
        <div>
          <div className="communication__banner-text">
            {authorizedUser?.package === PACKET_ENUM.BASIC && (
              <>
                Powiadomienia dotyczące spółek w ofercie Reliance oraz spółek
                wykupionych poza Reliance nie są dostępne w obecnym pakiecie.
                Aby uzyskać dostęp do tej funkcji, należy{" "}
                <b>wykupić wyższy pakiet.</b>
              </>
            )}
            {authorizedUser?.package === PACKET_ENUM.STANDARD && (
              <>
                Powiadomienia dotyczące spółek wykupionych poza Reliance nie są
                dostępne w obecnym pakiecie. Aby uzyskać dostęp do tej funkcji,
                należy <b>wykupić wyższy pakiet.</b>
              </>
            )}
          </div>
          <Button
            label="Sprawdź dostępne pakiety"
            styleType="strokeGray"
            size="small"
            onClick={() => setIsPacketModalOpen(true)}
            className="communication__banner-button"
          />
        </div>
        <img
          className="communication__banner-image"
          src={require("assets/images/CommunicationBanner.webp")}
          alt="communication banner"
        />
      </div>
    </>
  );
};

export default CommunicationBanner;
