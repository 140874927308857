import { useEffect, useState } from "react";
import { SelectOption } from "types/SelectTypes";
import "./Select.scss";
import Select, { components, ActionMeta, MultiValue } from "react-select";
import { FieldAttributes, useField, useFormikContext } from "formik";
import SVG from "shared/SVG/SVG";
import { SVG_ENUM } from "enums";

interface SelectProps extends FieldAttributes<any> {
  label?: string;
  options?: SelectOption[];
  placeholder?: string;
  name: string;
  filterOption?: any;
  onSelectChange?: (e: any) => void;
  className?: string;
  isSearchable?: boolean;
}

const CustomDropdownIndicator = (props: any) => {
  const { selectProps } = props;
  return (
    <components.DropdownIndicator {...props}>
      <SVG
        type={
          selectProps.menuIsOpen ? SVG_ENUM.CHEVRON_UP : SVG_ENUM.CHEVRON_DOWN
        }
      />
    </components.DropdownIndicator>
  );
};

const SelectComponent = ({
  label,
  options,
  name,
  filterOption,
  placeholder,
  onSelectChange,
  className = "",
  isSearchable = false,
  isMulti = false,
  ...props
}: SelectProps) => {
  const [selectedOption, setSelectedOption] = useState<
    SelectOption | MultiValue<SelectOption> | null
  >(null);
  const formik = useFormikContext();
  const [field, meta] = useField({ ...props, name });

  useEffect(() => {
    if (field.value !== undefined) {
      if (isMulti) {
        const initialValue = options?.filter((option) =>
          field.value.includes(option.value)
        );
        setSelectedOption(initialValue || null);
      } else {
        const initialValue = options?.find(
          (option) => option.value === field.value
        );
        setSelectedOption(initialValue || null);
      }
    } else {
      setSelectedOption(null);
    }
    // eslint-disable-next-line
  }, [field.value, options]);

  const handleChange = (
    newValue: SelectOption | MultiValue<SelectOption> | null,
    actionMeta?: ActionMeta<SelectOption>
  ) => {
    if (isMulti) {
      setSelectedOption(newValue as MultiValue<SelectOption>);
      const valueToStore = (newValue as MultiValue<SelectOption>)?.map(
        (option) => option.value
      );
      formik.setFieldValue(field.name, valueToStore);
      onSelectChange && onSelectChange(newValue);
    } else {
      setSelectedOption(newValue as SelectOption);
      // @ts-ignore
      const valueToStore = newValue ? newValue.value : "";
      formik.setFieldValue(field.name, valueToStore);
      onSelectChange && onSelectChange(newValue);
    }
  };
  return (
    <div className="select__wrapper">
      <div className="select__label">{label}</div>
      <Select
        {...props}
        value={selectedOption}
        name={name}
        components={{ DropdownIndicator: CustomDropdownIndicator }}
        onChange={handleChange}
        placeholder={placeholder ? placeholder : ""}
        options={options}
        className={`select ${className}`}
        classNamePrefix="select"
        filterOption={filterOption}
        isSearchable={isSearchable}
        noOptionsMessage={() => "Brak opcji"}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
      />
      {meta.touched && meta.error && (
        <div className="input__error input__error--select">
          <span>{meta.error}</span>
        </div>
      )}
    </div>
  );
};

export default SelectComponent;
