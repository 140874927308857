import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { queryClient } from "App";
import axiosInstance from "axiosConfig";

export const useStoreCustomer = (options?: UseMutationOptions<any, Error>) => {
  return useMutation({
    ...options,
    mutationKey: ["storeCustomer"],
    mutationFn: async () => {
      return axiosInstance.post("/customers").then((res) => res.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["customers"] });
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
  });
};

export default useStoreCustomer;
