import { useEffect, useState } from "react";
import { Button, SideModal } from "shared";
import { CompanyType } from "types/CompanyTypes";
import { CompanyDetailsTable } from "./components";
import "./CompanyDetailsModal.scss";
import { isDesktop } from "utils";
import TransactionsTable from "../TransactionsSideModal/components/TransactionsTable";
import { CompaniesTabsType } from "screens/companiesPage/CompaniesPage";

interface CompanyDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  company: CompanyType | null;
  setAddClient?: (company: CompanyType) => void;
  companiesTab: CompaniesTabsType["tab"];
}

interface TabType {
  tab: "general" | "transactions";
}

const CompanyDetailsModal: React.FC<CompanyDetailsModalProps> = ({
  isOpen,
  onClose,
  company,
  setAddClient,
  companiesTab,
}) => {
  const [tab, setTab] = useState<TabType["tab"]>("general");

  useEffect(() => {
    setTab("general");
  }, [company]);

  return (
    <SideModal
      isOpen={isOpen}
      onClose={onClose}
      title="Szczegółowe informacje"
      subtitle={company?.name}
      className="company-details-modal"
    >
      <>
        {(companiesTab === "my" || companiesTab === "archive") &&
          !isDesktop() && (
            <div className="side-modal__options">
              <span
                className={`side-modal__option ${
                  tab === "general" ? "side-modal__option--active" : ""
                }`}
                onClick={() => setTab("general")}
              >
                Dane ogólne
              </span>
              <span
                className={`side-modal__option ${
                  tab === "transactions" ? "side-modal__option--active" : ""
                }`}
                onClick={() => setTab("transactions")}
              >
                Lista transakcji
              </span>
            </div>
          )}
        {tab === "general" && <CompanyDetailsTable company={company} />}
        {tab === "transactions" && (
          <>
            {setAddClient && (
              <Button
                className="button--rounded company-info-modal__button"
                onClick={() => company && setAddClient(company)}
                label="Dodaj transakcję  +"
                styleType={isDesktop() ? undefined : "primary"}
              />
            )}
            {/* @ts-ignore */}
            {isOpen && <TransactionsTable company={company} />}
          </>
        )}
      </>
    </SideModal>
  );
};

export default CompanyDetailsModal;
