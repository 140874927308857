import { ROLES_ENUM, VALIDATION_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useGetAddress, useStoreAddress } from "hooks/addresses";
import { useGetMe } from "hooks/auth";
import { useGetCountries } from "hooks/countries";
import { useEffect, useRef, useState } from "react";
import { Button, Input, SelectComponent } from "shared";
import { UserType } from "types/UsersTypes";
import * as Yup from "yup";

interface PersonalAddressProps {
  onClose: () => void;
  user: UserType | null;
}

const PersonalAddress: React.FC<PersonalAddressProps> = ({ onClose, user }) => {
  const { data: countries } = useGetCountries();
  const { data: authorizedUser } = useGetMe();
  const isAdmin = authorizedUser?.role === ROLES_ENUM.ADMIN;
  const { data } = useGetAddress(isAdmin ? user?.uuid : undefined);
  const formRef = useRef<any>(null);
  const { mutateAsync: storeAddress } = useStoreAddress();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (countries && formRef.current && !data?.countryUuid) {
      const poland = countries.find((country) => country.label === "Polska");
      formRef.current.setFieldValue(
        "countryUuid",
        poland?.value || countries[0].value
      );
    }
  }, [countries, data]);

  const initialValues = {
    address: data?.address || "",
    postalCode: data?.postalCode || "",
    city: data?.city || "",
    countryUuid: data?.countryUuid || "",
  };

  const onSubmit = async (values: typeof initialValues) => {
    setIsSubmitting(true);
    try {
      await storeAddress(
        {
          ...values,
          userUuid: isAdmin ? user?.uuid : undefined,
          type: "personal",
        },
        {
          onSuccess: onClose,
          onSettled: () => {
            setIsSubmitting(false);
          },
        }
      );
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const validation = () =>
    Yup.object().shape({
      address: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .max(50)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
      postalCode: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .max(50)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
      city: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .max(50)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
      countryUuid: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
    });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={validation()}
      enableReinitialize
      innerRef={formRef}
    >
      {({ errors, values, handleSubmit }) => (
        <Form className="m-width-100">
          <Field
            type="text"
            id="address"
            name="address"
            label={"Adres"}
            as={Input}
          />

          <Field
            type="text"
            id="postalCode"
            name="postalCode"
            label={"Kod pocztowy"}
            as={Input}
          />
          <Field
            type="text"
            id="city"
            name="city"
            label={"Miasto"}
            as={Input}
          />

          <Field
            label="Kraj"
            name="countryUuid"
            id="countryUuid"
            as={SelectComponent}
            options={countries}
            className="select--left"
          />

          <div className="fullscreen-modal__buttons">
            <Button
              label="Anuluj"
              onClick={onClose}
              className="button--stroke button--rounded"
            />
            <Button
              label={"Zapisz zmiany"}
              type="submit"
              onClick={() => null}
              className=" button--rounded button--black"
              isLoading={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default PersonalAddress;
