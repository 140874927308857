import { Button, Modal } from "shared";

interface DeleteCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  cardLastFour?: string;
}

const DeleteCardModal = ({ isOpen, onClose, onConfirm, cardLastFour }: DeleteCardModalProps) => {
  return (
    <Modal isOpened={isOpen} onClose={onClose}>
      <>
        <div className="modal-heading">Usuwanie karty płatniczej</div>
        <div className="modal-description">
          Czy jesteś pewien, że chcesz usunąć kartę
          {cardLastFour && <span>{` ****${cardLastFour}`}</span>}?
        </div>
        <div className="modal-buttons">
          <Button
            className="button--stroke button--rounded"
            onClick={onClose}
            label="Anuluj"
          />
          <Button
            className="button--black button--rounded"
            onClick={onConfirm}
            label="Potwierdź"
          />
        </div>
      </>
    </Modal>
  );
};

export default DeleteCardModal;