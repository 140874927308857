import { ROLES_ENUM, SVG_ENUM } from "enums";
import { EditAddressDataModal } from "features/users";
import { useGetAddress } from "hooks/addresses";
import { useGetMe } from "hooks/auth";
import { useGetCountries } from "hooks/countries";
import React, { useState } from "react";
import { Button, SVG } from "shared";
import { UserType } from "types/UsersTypes";

interface AdressDataProps {
  user: UserType | undefined;
  handleGoBack: () => void;
}

const AdressData: React.FC<AdressDataProps> = ({ user, handleGoBack }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: authorizedUser } = useGetMe();
  const isAdmin = authorizedUser?.role === ROLES_ENUM.ADMIN;
  const { data } = useGetAddress(isAdmin ? user?.uuid : undefined);
  const { data: countries } = useGetCountries();
  const isEdit = !!data;

  return (
    <div className="profile__right-wrapper">
      <EditAddressDataModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        user={user || null}
      />
      <div className="profile__right-back" onClick={() => handleGoBack()}>
        <SVG type={SVG_ENUM.ARROW_LEFT} /> Wróć
      </div>
      <h2 className="profile__menu-header">
        <span className="profile__menu-icon profile__menu-icon--right">
          <SVG type={SVG_ENUM.PIN} />
        </span>
        Dane adresowe
      </h2>
      {data?.type ? (
        <table className="profile__table">
          {data?.type === "company" ? (
            <>
              <tr>
                <td className="profile__table-label">Nazwa firmy:</td>
                <td className="profile__table-cell">
                  {data?.companyName || "-"}
                </td>
              </tr>
              <tr>
                <td className="profile__table-label">NIP:</td>
                <td className="profile__table-cell">{data?.nip || "-"}</td>
              </tr>
            </>
          ) : null}
          <tr>
            <td className="profile__table-label">Adres:</td>
            <td className="profile__table-cell">{data?.address || "-"}</td>
          </tr>
          <tr>
            <td className="profile__table-label">Kod pocztowy:</td>
            <td className="profile__table-cell">{data?.postalCode || "-"}</td>
          </tr>
          <tr>
            <td className="profile__table-label">Miasto:</td>
            <td className="profile__table-cell">{data?.city || "-"}</td>
          </tr>
          <tr>
            <td className="profile__table-label">Kraj:</td>
            <td className="profile__table-cell">
              {countries?.find((country) => country.value === data?.countryUuid)
                ?.label || "-"}
            </td>
          </tr>
        </table>
      ) : null}
      <Button
        onClick={() => setIsModalOpen(true)}
        className="profile__button button--stroke button--rounded"
        label={isEdit ? "Edytuj" : "Dodaj +"}
      />
    </div>
  );
};

export default AdressData;
