import moment from "moment";
import React from "react";
import { AnalyzeStatusBadge } from "shared";
import { AnalyzeType } from "types/AnalyzeTypes";

interface AnalyzeTableProps {
  analyze: AnalyzeType | null;
  isAdmin?: boolean;
}

const AnalyzeTable: React.FC<AnalyzeTableProps> = ({ analyze, isAdmin }) => {
  return (
    <table className="side-modal__table">
      <tr>
        <td className="side-modal__table-label">Nazwa spółki:</td>
        <td className="side-modal__table-cell">
          {analyze?.companyName || "-"}
        </td>
      </tr>
      <tr>
        <td className="side-modal__table-label">NIP spółki:</td>
        <td className="side-modal__table-cell">{analyze?.nip || "-"}</td>
      </tr>
      <tr>
        <td className="side-modal__table-label">Data zlecenia:</td>
        <td className="side-modal__table-cell">
          {moment(analyze?.createdAt).format("DD/MM/YYYY") || "-"}
        </td>
      </tr>
      {isAdmin && (
        <tr>
          <td className="side-modal__table-label">Zlecający:</td>
          <td className="side-modal__table-cell">
            {analyze?.user?.name || "-"}
          </td>
        </tr>
      )}

      <tr>
        <td className="side-modal__table-label">Termin wykonania:</td>
        <td className="side-modal__table-cell">
          {moment(analyze?.deadlineAt).format("DD/MM/YYYY") || "-"}
          {analyze?.status === "pending" ? (
            <span className="companies-table__unconfirmed-badge">
              Niepotwierdzony
            </span>
          ) : null}
        </td>
      </tr>

      {analyze?.status && (
        <tr>
          <td className="side-modal__table-label">Status:</td>
          <td className="side-modal__table-cell">
            <AnalyzeStatusBadge
              className="analyze-status--onlyDesktopVersion"
              status={analyze?.status}
            />
          </td>
        </tr>
      )}
      {analyze?.explanation && (
        <tr>
          <td className="side-modal__table-label">Wyjaśnienie:</td>
          <td className="side-modal__table-cell">
            {analyze?.explanation || "-"}
          </td>
        </tr>
      )}
    </table>
  );
};

export default AnalyzeTable;
