import { VALIDATION_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useGetPhonePrefixes } from "hooks/phone";
import { useUpdatePersonalData } from "hooks/users";
import { useEffect, useRef, useState } from "react";
import "./EditPersonalData.scss";
import {
  Button,
  FullscreenModal,
  Input,
  PhoneWithPrefix,
  SelectComponent,
  Warning,
} from "shared";
import { UserType } from "types/UsersTypes";
import * as Yup from "yup";
import { validatePhoneNumber } from "utils/validation/phoneValidation";

interface EditPersonalDataModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  user: UserType | null;
}

const EditPersonalDataModal: React.FC<EditPersonalDataModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  user,
}) => {
  const { data: phonePrefixes } = useGetPhonePrefixes();
  const { mutateAsync: updatePersonalData } = useUpdatePersonalData();
  const formRef = useRef<any>(null);
  const [showPhoneWarning, setShowPhoneWarning] = useState(false);
  const [showEmailWarning, setShowEmailWarning] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    phonePrefix: user?.phonePrefixUuid || "",
  };

  useEffect(() => {
    if (phonePrefixes && formRef.current) {
      const phonePrefix = phonePrefixes.find(
        (prefix) => prefix.value === user?.phonePrefixUuid
      );
      formRef.current.setFieldValue(
        "phonePrefix",
        phonePrefix || phonePrefixes[0].value
      );
    }
  }, [phonePrefixes, user]);

  const onSubmit = async (values: typeof initialValues) => {
    setIsSubmitting(true);
    await updatePersonalData(
      {
        email: values.email,
        phoneNumber: values.phoneNumber,
        phonePrefixUuid: values.phonePrefix,
        // uuid: user?.uuid,
      },
      {
        onSettled: () => {
          setIsSubmitting(false);
        },
        onSuccess: () => {
          onSuccess && onSuccess();
          onClose();
        },
        onError: (error) => {
          switch (error.data.message) {
            case "Email exist":
              setShowEmailWarning(true);
              break;
            case "Phone number exist":
              setShowPhoneWarning(true);
              break;
            case "Phone number and email exist":
              setShowPhoneWarning(true);
              setShowEmailWarning(true);
              break;
            default:
              break;
          }
        },
      }
    );

    onClose();
  };

  const validation = () =>
    Yup.object().shape({
      phoneNumber: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .min(9, VALIDATION_ENUM.MIN_9)
        .max(12, VALIDATION_ENUM.MAX_12)
        .test("is-digitals-only", VALIDATION_ENUM.NUMBER, (value) => {
          return /^\d+$/.test(value);
        })
        .test("phone-validation", VALIDATION_ENUM.NUMBER, function (value) {
          if (!/^\d+$/.test(value)) {
            return false;
          }

          const { phonePrefixUuid } = this.parent;

          if (value && phonePrefixUuid && phonePrefixes?.length) {
            const validation = validatePhoneNumber(
              value,
              phonePrefixUuid,
              phonePrefixes
            );

            if (!validation.isValid) {
              return this.createError({
                message: validation.error || VALIDATION_ENUM.NUMBER,
              });
            }
          }

          return true;
        }),

      phonePrefix: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      email: Yup.string().email(VALIDATION_ENUM.WRONG_EMAIL),
    });

  return (
    <FullscreenModal
      isOpen={isOpen}
      onClose={onClose}
      className="edit-personal-data"
    >
      <h2 className="fullscreen-modal__header">Edycja danych osobowych</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={validation()}
        enableReinitialize
        innerRef={formRef}
      >
        {({ errors, values, handleSubmit }) => (
          <Form className="m-width-100">
            <PhoneWithPrefix>
              <Field
                label="Telefon"
                name="phonePrefix"
                id="phonePrefix"
                as={SelectComponent}
                options={phonePrefixes}
              />
              <Field
                name="phoneNumber"
                type="number"
                id="phoneNumber"
                maxLength="12"
                as={Input}
                onInputChange={() => setShowPhoneWarning(false)}
              />
            </PhoneWithPrefix>
            <Warning
              className={`edit-personal-data__warning ${
                showPhoneWarning ? "" : "edit-personal-data__warning--hidden"
              }`}
              text={
                "Podany numer telefonu jest już zarejestrowany w naszym systemie. Proszę podać inny numer."
              }
            />

            <Field
              type="text"
              id="email"
              name="email"
              optional
              label={"Email"}
              as={Input}
              onInputChange={() => setShowEmailWarning(false)}
            />
            <Warning
              className={`edit-personal-data__warning ${
                showEmailWarning ? "" : "edit-personal-data__warning--hidden"
              }`}
              text={
                "Podany adres email jest już zarejestrowany w naszym systemie. Proszę podać inny adres email."
              }
            />

            <div className="fullscreen-modal__buttons">
              <Button
                label="Anuluj"
                onClick={onClose}
                className="button--stroke button--rounded"
              />
              <Button
                label={"Zapisz zmiany"}
                type="submit"
                onClick={() => null}
                className=" button--rounded button--black"
                isLoading={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </FullscreenModal>
  );
};

export default EditPersonalDataModal;
