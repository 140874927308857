import React from "react";
import { Field } from "formik";
import { Checkbox } from "shared";
import { useGetSettings, useUpdateSettings } from "hooks/settings";

interface CommunicationRowProps {
  title: string;
  description: string;
  disabled?: boolean;
  settingsKey: string;
  smsDisabled?: boolean;
  emailDisabled?: boolean;
}

const CommunicationRow: React.FC<CommunicationRowProps> = ({
  title,
  description,
  disabled = false,
  settingsKey,
  smsDisabled = false,
  emailDisabled = false,
}) => {
  const { data: settings } = useGetSettings();
  const { mutateAsync: updateSettings } = useUpdateSettings();

  const smsValue = settings?.find(
    (setting) => `${settingsKey}-sms` === setting.name
  )?.valueBoolean;

  const emailValue = settings?.find(
    (setting) => `${settingsKey}-email` === setting.name
  )?.valueBoolean;

  return (
    <div
      className={`communication__grid-row ${
        disabled ? "communication__grid-row--disabled" : ""
      }`}
    >
      <div className="row-title">
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
      <div className="checkbox-cell">
        <Field
          type="checkbox"
          name={`${settingsKey}-sms`}
          as={Checkbox}
          disabled={disabled || smsDisabled}
          onInputChange={async () => {
            await updateSettings({
              name: `${settingsKey}-sms`,
              valueBoolean: !smsValue,
            });
          }}
        />
      </div>
      <div className="checkbox-cell">
        <Field
          type="checkbox"
          name={`${settingsKey}-email`}
          as={Checkbox}
          disabled={disabled || emailDisabled}
          onInputChange={async () => {
            await updateSettings({
              name: `${settingsKey}-email`,
              valueBoolean: !emailValue,
            });
          }}
        />
      </div>
    </div>
  );
};

export default CommunicationRow;
