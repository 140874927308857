/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Table from "rc-table";
import {
  mobileColumns,
  desktopColumns,
  desktopColumnsOtherForAdmin,
  mobileColumnsOthers,
} from "./utils";
import { Banner, Button, Pagination } from "shared";
import { isDesktop } from "utils";
import "./companies-table.scss";
import { useSearchParams } from "react-router-dom";
import { useGetCompanies } from "hooks/companies";
import { useGetIndustriesHook } from "hooks/industries";
import {
  CompanyMyType,
  CompanyStatusType,
  CompanyType,
} from "types/CompanyTypes";
import { Tooltip } from "react-tooltip";
import { ArchiveTab, MyCompaniesTab } from "./components";
import { useGetMe } from "hooks/auth";
import { PACKET_ENUM, ROLES_ENUM } from "enums";
import { CompaniesTabsType } from "screens/companiesPage/CompaniesPage";
import { useGetUnviewedPostsCount } from "hooks/posts";

interface CompaniesTableType {
  page: number;
  setPage: (page: number) => void;
  tab: CompaniesTabsType["tab"];
  onRowClick?: (
    record: CompanyType,
    e: React.MouseEvent<any, MouseEvent>
  ) => void;
  setEditCompany: (company: CompanyType) => void;
  handleStatusChange: (
    company: CompanyType,
    status: CompanyStatusType["status"]
  ) => void;
  setShowSellShares: (company: CompanyMyType) => void;
  setShowDelete: (company: CompanyMyType) => void;
  setModalManage: ({
    type,
    company,
  }: {
    type: string;
    company: CompanyType | CompanyMyType | null;
  }) => void;
}

const CompaniesTable = ({
  setPage,
  page,
  tab,
  onRowClick,
  setEditCompany,
  handleStatusChange,
  setShowSellShares,
  setShowDelete,
  setModalManage,
}: CompaniesTableType) => {
  const { data: industries } = useGetIndustriesHook();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: authorizedUser } = useGetMe();
  const { data, refetch } = useGetCompanies(
    page,
    tab === "reliance" || tab === "other",
    tab,
    authorizedUser?.role === ROLES_ENUM.ADMIN
  );
  useEffect(() => {
    refetch();
  }, [page, tab]);

  const handleChangePage = (e: number) => {
    setPage(e);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", e.toString());
    newSearchParams.set("role", tab);
    setSearchParams(newSearchParams);
  };
  const isAdmin = authorizedUser?.role === ROLES_ENUM.ADMIN;

  const { data: unviewedPostCount } = useGetUnviewedPostsCount({
    companyIds: data?.data?.map((item: any) => item.id),
  });

  return (
    <div className="">
      <Tooltip
        place="top"
        className="tooltip tooltip--noShadow"
        id="company-tooltip"
      />
      {tab === "my" ? (
        <MyCompaniesTab
          industries={industries}
          tab={tab}
          setEditCompany={setEditCompany}
          handleStatusChange={handleStatusChange}
          onRowClick={onRowClick}
          setShowSellShares={setShowSellShares}
          setShowDelete={setShowDelete}
          setModalManage={setModalManage}
        />
      ) : null}

      {tab === "reliance" ? (
        <>
          {!isDesktop() && isAdmin && (
            <Button
              className="dashboard__top-button button--rounded  dashboard__top-button--add-company dashboard__top-button--add-company-admin"
              onClick={() =>
                setModalManage({ type: "addCompany", company: null })
              }
              label="Dodaj spółkę  +"
              size="medium"
              styleType="primary"
            />
          )}
          {!isAdmin && !authorizedUser?.package && (
            <Banner
              text={
                <p>
                  Aby uzyskać dostęp do informacji rynkowych i powiadomień{" "}
                  <b>konieczne jest wykupienie pakietu</b>
                </p>
              }
              buttonLabel="Sprawdź dostępne pakiety"
              buttonOnClick={() =>
                setModalManage({ type: "packet", company: null })
              }
              className={`companies__top-banner `}
            />
          )}
          {!isAdmin && authorizedUser?.package === PACKET_ENUM.BASIC && (
            <Banner
              text={
                <p>
                  <b>Wykup wyższy pakiet</b>, aby uzyskać dostęp do informacji
                  rynkowych na temat spółek reliance
                </p>
              }
              buttonLabel="Sprawdź dostępne pakiety"
              buttonOnClick={() =>
                setModalManage({ type: "packet", company: null })
              }
              className={`companies__top-banner `}
            />
          )}
          <Table
            rowKey="id"
            className={`companies-table companies-table--reliance ${
              !authorizedUser?.package ||
              authorizedUser.package === PACKET_ENUM.BASIC
                ? "companies-table--no-access "
                : " "
            }`}
            data={data?.data}
            onRow={(record: any) => ({
              onClick: (e) => onRowClick && onRowClick(record, e),
            })}
            columns={
              isDesktop()
                ? desktopColumns({
                    setEditCompany,
                    handleStatusChange,
                    isAdmin,
                    setModalManage,
                    unviewedPostCount,
                  })
                : mobileColumns({
                    setEditCompany,
                    handleStatusChange,
                    isAdmin: authorizedUser?.role === ROLES_ENUM.ADMIN,
                    setModalManage,
                    unviewedPostCount,
                  })
            }
          />
          {data?.meta && data?.meta?.lastPage > 1 && (
            <Pagination
              totalPages={data?.meta?.lastPage}
              currentPage={data?.meta?.currentPage || page}
              onPageChange={(e: any) => handleChangePage(e)}
            />
          )}
        </>
      ) : null}
      {tab === "other" ? (
        <>
          <Table
            rowKey="id"
            className={`companies-table ${
              isAdmin ? "companies-table--admin-other" : ""
            }`}
            data={data?.data}
            onRow={(record: any) => ({
              onClick: (e) => onRowClick && onRowClick(record, e),
            })}
            columns={
              isDesktop()
                ? isAdmin
                  ? desktopColumnsOtherForAdmin()
                  : desktopColumns({
                      setEditCompany,
                      handleStatusChange,
                      isAdmin: authorizedUser?.role === ROLES_ENUM.ADMIN,
                      setModalManage,
                    })
                : mobileColumnsOthers({ isAdmin })
            }
          />
          {data?.meta && data?.meta?.lastPage > 1 && (
            <Pagination
              totalPages={data?.meta?.lastPage}
              currentPage={data?.meta?.currentPage || page}
              onPageChange={(e: any) => handleChangePage(e)}
            />
          )}
        </>
      ) : null}

      {tab === "archive" ? (
        <ArchiveTab tab={tab} onRowClick={onRowClick} />
      ) : null}
    </div>
  );
};

export default CompaniesTable;
