import { VALIDATION_ENUM } from "enums";
import React, { useEffect, useRef } from "react";
import "./AccountFromStep.scss";
import { Button, Input, PhoneWithPrefix, SelectComponent } from "shared";
import { useGetPhonePrefixes } from "hooks/phone";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useGetSmsCode } from "hooks/auth";
import { useCreateAccount } from "hooks/users";
import { validatePhoneNumber } from "utils/validation/phoneValidation";

interface Props {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  setStepOptions: any;
  stepOptions: any;
  formData?: any;
}

const AccountFromStep: React.FC<Props> = ({
  goToNextStep,
  goToPreviousStep,
  setStepOptions,
  stepOptions,
  formData,
}) => {
  const { data: phonePrefixes } = useGetPhonePrefixes();
  const formRef = useRef<any>(null);

  const { mutate: getSmsCode } = useGetSmsCode({
    onSuccess: () => {
      goToNextStep();
    },
    onError: (error: Error) => {
      console.error("Błąd podczas logowania:", error.message);
    },
  });

  const { mutate } = useCreateAccount({
    onSuccess: (values, variables) => {
      getSmsCode(variables);
      if (setStepOptions)
        setStepOptions({
          ...stepOptions,
          AccountFromStep: { data: variables },
        });
    },
    onError: (error: Error) => {
      console.error("Błąd podczas logowania:", error.message);
    },
  });

  const validation = () =>
    Yup.object().shape({
      name: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
      phonePrefixUuid: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      phoneNumber: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .min(9, VALIDATION_ENUM.MIN_9)
        .max(12, VALIDATION_ENUM.MAX_12)
        .test("is-digitals-only", VALIDATION_ENUM.NUMBER, (value) => {
          return /^\d+$/.test(value);
        })
        .test("phone-validation", VALIDATION_ENUM.NUMBER, function (value) {
          if (!/^\d+$/.test(value)) {
            return false;
          }

          const { phonePrefixUuid } = this.parent;

          if (value && phonePrefixUuid && phonePrefixes?.length) {
            const validation = validatePhoneNumber(
              value,
              phonePrefixUuid,
              phonePrefixes
            );

            if (!validation.isValid) {
              return this.createError({
                message: validation.error || VALIDATION_ENUM.NUMBER,
              });
            }
          }

          return true;
        }),
    });

  const initialValues = {
    name: "",
    phonePrefixUuid: "",
    phoneNumber: "",
    packet: formData && formData.packet,
  };

  const onSubmit = (values: typeof initialValues) => {
    mutate({ ...values, phoneNumber: values.phoneNumber.toString() });
  };

  useEffect(() => {
    if (phonePrefixes && !!formRef.current) {
      const defaultPhonePrefix = phonePrefixes.find(
        (prefix) => prefix.label === "+48"
      );
      formRef?.current?.setFieldValue(
        "phonePrefixUuid",
        defaultPhonePrefix?.value
      );
    }
  }, [phonePrefixes]);

  return (
    <>
      <h2 className="fullscreen-modal__header packet-modal__header">
        Uzupełnij swoje dane
      </h2>
      <div className="account-form-step">
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => onSubmit(values)}
          validationSchema={validation()}
          innerRef={formRef}
        >
          {({ errors, values, handleSubmit }) => (
            <Form>
              <Field
                type="text"
                id="name"
                name="name"
                label={"Imię i nazwisko"}
                as={Input}
                maxLength={32}
              />
              <PhoneWithPrefix>
                <Field
                  label="Telefon"
                  name="phonePrefixUuid"
                  id="phonePrefixUuid"
                  as={SelectComponent}
                  options={phonePrefixes}
                />
                <Field
                  name="phoneNumber"
                  type="number"
                  id="phoneNumber"
                  maxLength={12}
                  as={Input}
                />
              </PhoneWithPrefix>
              <div className="fullscreen-modal__buttons">
                <Button
                  label="Poprzedni krok"
                  className="button--stroke button--rounded"
                  onClick={() => {
                    goToPreviousStep();
                  }}
                />
                <Button
                  label="Dalej"
                  className="button--black button--rounded"
                  onClick={() => {
                    handleSubmit();
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AccountFromStep;
