enum VALIDATION_ENUM {
  REQUIRED = "To pole jest wymagane",
  EMPTY_SPACE = "To pole nie może zawierać spacji",
  WRONG_EMAIL = "Niepoprawny adres email",
  MIN_8 = "Minimum 8 znaków",
  CONFIRM_PASSWORD = "Hasła muszą być takie same",
  MIN_9 = "Minimum 9 znaków",
  MAX_12 = "Maksymalnie 12 znaków",
  CANNOT_START_AND_END_WITH_SPACE = "Nie może zaczynać się ani kończyć spacją",
  MIN3 = "Minimum 3 znaki",
  MIN_2 = "Minimum 2 znaki",
  NUMBER = "To pole może zawierać wyłącznie cyfry",
  PASSWORD_MUST_BE_DIFFERENT = "Nowe hasło musi być różne od starego",
  POST_PUBLISHED = "Status 'opublikowana' może być ustawiony tylko dla wpisów z przeszłości",
  POST_PLANNED = "Status 'zaplanowana' może być ustawiony tylko dla wpisów z przyszłości",
  MAX_50 = "Maksymalnie 50 znaków",
  MIN_1 = "Wartość nie może być mniejsza niż 1",
  INVALID_PHONE_FORMAT = "Niepoprawny format numeru telefonu",
  INVALID_PHONE_NUMBER = "Niepoprawny numer telefonu",
  INVALID_PHONE_NUMBER_FOR_THIS_COUNTRY = "Niepoprawny numer telefonu",
  CANNOT_START_WITH_0 = "To pole nie może zaczynać się od 0",
}

export default VALIDATION_ENUM;
