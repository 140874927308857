import { SVG_ENUM } from "enums";
import React, { useState } from "react";
import { Button, SVG } from "shared";
import { UserType } from "types/UsersTypes";
import "./Payments.scss";
import { DeleteSubscriptionModal, PacketModal } from "features/users";
import { AddPaymentMethodModal, DeleteCardModal } from "./components";
import { useGetMe } from "hooks/auth";
import {
  useGetPaymentMethods,
  useGetPayments,
  UpdatePaymentMethodHook,
  DeletePaymentMethodHook,
} from "hooks/payments";
import { PACKET_PRICE_ENUM } from "enums/PacketsEnum";
import {
  UpdatePaymentMethodType,
  DeletePaymentMethodType,
} from "types/PaymentTypes";
import { useGetSubscription } from "hooks/subscriptions";
import { hasNextPayment } from "utils/subscriptionUtils";
import moment from "moment";
import { useGetCustomer } from "hooks/customers";
import InvoiceMailing from "./components/InvoiceMailing";

interface PaymentsProps {
  user: UserType | undefined;
  handleGoBack: () => void;
}

const Payments: React.FC<PaymentsProps> = ({ user, handleGoBack }) => {
  const [isPacketModalOpen, setIsPacketModalOpen] = useState(false);
  const [isDeleteSubscriptionModalOpen, setIsDeleteSubscriptionModalOpen] =
    useState(false);
  const [isAddPaymentMethodModalOpen, setIsAddPaymentMethodModalOpen] =
    useState(false);
  const [selectedCardToDelete, setSelectedCardToDelete] =
    useState<DeletePaymentMethodType>();
  const [isDeleteCardModalOpen, setIsDeleteCardModalOpen] = useState(false);
  const { data: me } = useGetMe();
  const { data: paymentMethods, refetch: refetchCardsList } =
    useGetPaymentMethods();
  const { data: payments } = useGetPayments();
  const { data: customer } = useGetCustomer(!!me);
  const { data: subscription } = useGetSubscription();
  const isDeleteSubscription = !!subscription?.canceled_at && customer?.plan;
  const isDowngrade = !!customer?.planDowngradedTo && !isDeleteSubscription;
  const isDefaultPaymentMethod = paymentMethods?.find(
    (paymentMethod) => paymentMethod.default
  );

  const { mutate: updatePaymentMethod } = UpdatePaymentMethodHook({
    onSuccess: () => {
      refetchCardsList();
    },
  });
  const { mutate: deletePaymentMethod } = DeletePaymentMethodHook();

  const choosePaymentMethod = async (id: UpdatePaymentMethodType) => {
    await updatePaymentMethod(id);
  };
  const deleteCard = async (id: DeletePaymentMethodType) => {
    await deletePaymentMethod(id);
  };

  const handleDeleteCard = (id: DeletePaymentMethodType, last4: string) => {
    setSelectedCardToDelete(id);
    setIsDeleteCardModalOpen(true);
  };

  const confirmDeleteCard = async () => {
    if (selectedCardToDelete) {
      await deleteCard(selectedCardToDelete);
      setIsDeleteCardModalOpen(false);
    }
  };

  return (
    <div className="profile__right-wrapper payments">
      <PacketModal
        isOpen={isPacketModalOpen}
        onClose={() => setIsPacketModalOpen(false)}
        parent="payment"
      />

      <DeleteSubscriptionModal
        isOpen={isDeleteSubscriptionModalOpen}
        onClose={() => setIsDeleteSubscriptionModalOpen(false)}
        customer={customer}
      />

      <AddPaymentMethodModal
        isOpen={isAddPaymentMethodModalOpen}
        onClose={() => setIsAddPaymentMethodModalOpen(false)}
      />

      <DeleteCardModal
        isOpen={isDeleteCardModalOpen}
        onClose={() => setIsDeleteCardModalOpen(false)}
        onConfirm={confirmDeleteCard}
        cardLastFour={
          paymentMethods?.find((pm) => pm.id === selectedCardToDelete)?.card
            .last4
        }
      />

      <div className="profile__right-back" onClick={() => handleGoBack()}>
        <SVG type={SVG_ENUM.ARROW_LEFT} /> Wróć
      </div>
      <h2 className="profile__menu-header">
        <span className="profile__menu-icon profile__menu-icon--right">
          <SVG type={SVG_ENUM.CREDIT_CARD} />
        </span>
        Płatności
      </h2>
      <div className="payments__package-wrapper">
        <p className="payments__label">
          {isDowngrade ? "Twój obecny pakiet" : "Twój pakiet"}
        </p>
        {customer?.plan ? (
          <div className="payments__package">
            <SVG
              type={SVG_ENUM.LOGIN_BG_MOBILE}
              className="payments__package-bg"
            />
            <div className="payments__package-left">
              <p className="payments__package-name">Pakiet {customer?.plan}</p>
              <p>
                <span className="payments__package-price">
                  {
                    PACKET_PRICE_ENUM[
                      customer?.plan?.toLocaleUpperCase() as keyof typeof PACKET_PRICE_ENUM
                    ]
                  }
                </span>
                zł/msc
              </p>
            </div>
            {isDowngrade || isDeleteSubscription ? null : (
              <Button
                label="Zmień"
                className="button-rounded payments__package-button"
                onClick={() =>
                  isDefaultPaymentMethod
                    ? setIsPacketModalOpen(true)
                    : setIsAddPaymentMethodModalOpen(true)
                }
              />
            )}
          </div>
        ) : (
          <Button
            label="Wybierz pakiet"
            size="small"
            className="button--stroke button--rounded button--payments payments__choose-packet"
            onClick={() => setIsPacketModalOpen(true)}
          />
        )}
      </div>
      {isDowngrade ? (
        <>
          {" "}
          <p className="payments__label payments__label--downgrade">
            Zmiana pakietu od{" "}
            <p className=" payments__label--downgrade-gray">
              {subscription?.current_period_end &&
                moment
                  .unix(subscription?.current_period_end)
                  .locale("pl")
                  .format("D MMMM YYYY")}
            </p>
          </p>
          <div className="payments__package">
            <SVG
              type={SVG_ENUM.LOGIN_BG_MOBILE}
              className="payments__package-bg"
            />
            <div className="payments__package-left">
              <p className="payments__package-name">
                Pakiet {customer?.planDowngradedTo}
              </p>
              <p>
                <span className="payments__package-price">
                  {
                    PACKET_PRICE_ENUM[
                      customer?.planDowngradedTo?.toLocaleUpperCase() as keyof typeof PACKET_PRICE_ENUM
                    ]
                  }
                </span>
                zł/msc
              </p>
            </div>
          </div>
        </>
      ) : null}

      {isDeleteSubscription ? (
        <p className="payments__label payments__label--downgrade">
          Rezygnacja od{" "}
          <p className=" payments__label--downgrade-gray">
            {subscription?.canceled_at &&
              subscription.current_period_end &&
              moment
                .unix(subscription?.current_period_end)
                .locale("pl")
                .format("D MMMM YYYY")}
          </p>
        </p>
      ) : customer?.plan ? (
        <p
          className="payments__deactivate"
          onClick={() => setIsDeleteSubscriptionModalOpen(true)}
        >
          Rezygnuj z subskrybcji
        </p>
      ) : null}
      <div className="payments__payment-methods">
        <div className="payments__payment-methods--header">
          <p className="payments__label">Metody płatności</p>
          <Button
            onClick={() => setIsAddPaymentMethodModalOpen(true)}
            className="button--stroke button--rounded button--payments"
            size="small"
            label="Dodaj kartę płatniczą +"
          />
        </div>
        <div className="payments__payment-methods--items-wrapper">
          {!!paymentMethods.length && (
            <p className="payments__payment-methods--label">Zapisane karty</p>
          )}
          {paymentMethods?.map((paymentMethod, key) => (
            <>
              <div className="payments__payment-methods--item" key={key}>
                <div
                  className="payments__payment-methods--toggler"
                  onClick={() =>
                    !paymentMethod.default &&
                    choosePaymentMethod(paymentMethod.id)
                  }
                />
                <SVG
                  type={SVG_ENUM.CARD}
                  className="payments__payment-methods--card"
                />
                <p className="payments__payment-methods--number">
                  ****{paymentMethod.card.last4}
                </p>
                {paymentMethod.default ? (
                  <>
                    <div className="payments__payment-methods--options">
                      Wybrano
                    </div>
                    <SVG
                      type={SVG_ENUM.CIRCLE_CHECKED_GREEN}
                      className="payments__payment-methods--checked"
                    />
                    {!customer?.plan && (
                      <div
                        className="payments__payment-methods--options"
                        onClick={() =>
                          handleDeleteCard(
                            paymentMethod.id,
                            paymentMethod.card.last4
                          )
                        }
                      >
                        <SVG
                          type={SVG_ENUM.TRASH}
                          className="payments__payment-methods--trash"
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div
                    className="payments__payment-methods--options"
                    onClick={() =>
                      handleDeleteCard(
                        paymentMethod.id,
                        paymentMethod.card.last4
                      )
                    }
                  >
                    <SVG
                      type={SVG_ENUM.TRASH}
                      className="payments__payment-methods--trash"
                    />
                  </div>
                )}
              </div>
            </>
          ))}
        </div>
      </div>

      <div className="payments__invoices">
        <div className="payments__invoices--header">
          <p className="payments__label">Faktury</p>
          {subscription && hasNextPayment(subscription) && (
            <p className="payments__invoices--label">
              Następna płatność:{" "}
              <p className="payments__invoices--label--bold">
                {" "}
                {moment
                  .unix(subscription?.current_period_end!)
                  .format("DD/MM/YYYY")}
              </p>
            </p>
          )}
          {!payments.length && (
            <p className="payments__subtitle">
              W tym miejscu pojawią się Twoje faktury.
            </p>
          )}
        </div>
        <InvoiceMailing />
        <div className="payments__invoices--items-wrapper">
          {payments?.map((payment) => (
            <>
              <div className="payments__invoices--item">
                <p className="payments__invoices--date">
                  &ensp; {moment.unix(payment?.created).format("DD/MM/YYYY")}
                </p>
                <p className="payments__invoices--amount">
                  {payment.total / 100} zł
                </p>
                <a
                  className="payments__download"
                  href={payment.invoice_pdf}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SVG
                    type={SVG_ENUM.CLOUD_DOWNLOAD}
                    className="payments__invoices--cloud-icon"
                  />
                  <p className="payments__invoices--download">Pobierz</p>
                </a>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Payments;
