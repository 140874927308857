import { PACKET_ENUM } from "enums";

const isPacketEnought = (
  packet: PACKET_ENUM | undefined,
  requiredPacket: PACKET_ENUM
) => {
  const packetValues = Object.values(PACKET_ENUM);

  if (packet === undefined) {
    return false;
  }

  const packetIndex = packetValues.indexOf(packet);
  const requiredPacketIndex = packetValues.indexOf(requiredPacket);

  return packetIndex >= requiredPacketIndex;
};

export default isPacketEnought;
