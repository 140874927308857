import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { PaymentType } from "types/PaymentTypes";
// import { ClientType } from "types/ClientType";

export default function useGetPayments() {
  return useQuery({
    queryKey: ["payments"],
    queryFn: async () => {
      return axiosInstance.get("/payments").then((res) => res.data);
    },
    initialData: [],
    select: (data: PaymentType[]) => data,
    retry: false,
    // staleTime: 1000 * 60 * 5,
  });
}
