import { DashboardTopBar, TabsHandler } from "shared";
import "./ProfilePage.scss";
import {
  PersonalData,
  ProfileMenu,
  AdressData,
  Payments,
  Communication,
  Contact,
} from "./components";
import { useState, useEffect } from "react";
import { useGetMe } from "hooks/auth";
import { isDesktop } from "utils";

export enum profileTabsEnum {
  "personal",
  "location",
  "communication",
  "payments",
  "contact",
}

const ProfilePage = () => {
  const [tab, setTab] = useState<profileTabsEnum | null>(
    isDesktop() ? profileTabsEnum.personal : null
  );
  const { data: authorizedUser } = useGetMe();

  // Disable scrolling on mobile when a tab is open
  useEffect(() => {
    const html = document.documentElement;
const dashboard = document.querySelector(".dashboard");
    if
     (!isDesktop() && tab !== null) {
      html.classList.add("no-scroll");
      dashboard?.classList.add("overflow-hidden");

    } else {
      html.classList.remove("no-scroll");
      dashboard?.classList.remove("overflow-hidden");
    }

    return () => {
      html.classList.remove("no-scroll");
    };
  }, [tab]);

  const getTabIndex = () => {
    switch (tab) {
      case profileTabsEnum.personal:
        return 0;
      case profileTabsEnum.location:
        return 1;
      case profileTabsEnum.communication:
        return 2;
      case profileTabsEnum.payments:
        return 3;
      case profileTabsEnum.contact:
        return 4;
      default:
        return -1;
    }
  };

  const handleGoBack = () => {
    setTab(null);
  };

  return (
    <>
      <div className="profile">
        <DashboardTopBar>
          <h1 className="dashboard__header">Profil</h1>
        </DashboardTopBar>
        <div className="dashboard-content__wrapper">
          <div className="dashboard-content">
            <div className="profile__wrapper">
              <ProfileMenu tab={tab} setTab={setTab} />
              <div className="profile__right">
                <TabsHandler currentTab={getTabIndex()}>
                  <PersonalData
                    handleGoBack={handleGoBack}
                    user={authorizedUser}
                  />
                  <AdressData
                    handleGoBack={handleGoBack}
                    user={authorizedUser}
                  />
                  <Communication
                    handleGoBack={handleGoBack}
                    user={authorizedUser}
                  />

                  <Payments handleGoBack={handleGoBack} user={authorizedUser} />
                  <Contact handleGoBack={handleGoBack} user={authorizedUser} />
                </TabsHandler>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
