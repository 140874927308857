import { ClientType } from "types/ClientType";
import { downloadFile, getCurrencyFromUuid } from "utils";
import { SharesByCompanyType } from "types/SharesTypes";
import { CompanyMyType, CompanyType } from "types/CompanyTypes";
import moment from "moment";
import { SVG_ENUM } from "enums";
import { SVG } from "shared";

export const mobileColumns = ({ company }: { company: CompanyType | null }) => [
  {
    width: "calc(100% - 20px)",
    render: (data: ClientType) => (
      <table className="side-modal__table">
        <tr>
          <td className="side-modal__table-label">Klient:</td>
          <td className="side-modal__table-cell">{data?.name || "-"}</td>
        </tr>
        <tr>
          <td className="side-modal__table-label">Data:</td>
          <td className="side-modal__table-cell">
            {moment(data.createdAt).format("DD/MM/YYYY")}
          </td>
        </tr>
        <tr>
          <td className="side-modal__table-label">Wykupione udziały:</td>
          <td className="side-modal__table-cell">{data.sharesCount || "-"}</td>
        </tr>
        <tr>
          <td className="side-modal__table-label">Wartość udziałów:</td>
          <td className="side-modal__table-cell">
            {data?.sharesValue || "-"}{" "}
            {data?.pricePerShare && company
              ? getCurrencyFromUuid(company?.currencyUuid)
              : null}
          </td>
        </tr>

        <tr>
          <td className="side-modal__table-label">Wartość jednego udziału:</td>
          <td className="side-modal__table-cell">
            {data?.pricePerShare || "-"}{" "}
            {data?.pricePerShare && company
              ? getCurrencyFromUuid(company?.currencyUuid)
              : null}
          </td>
        </tr>
      </table>
    ),
  },
];

export const desktopColumns = ({
  company,
}: {
  company: CompanyType | null;
}) => {
  return [
    {
      title: "Klient",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Data",
      width: 150,
      dataIndex: "completedAt",
      key: "completedAt",
      render: (item: string) => moment(item).format("DD/MM/YYYY"),
    },
    {
      title: "Ilość udziałów",
      width: 200,
      render: (item: SharesByCompanyType) => (
        <span className={`transactions-table__number`}>
          {/* {item?.type === "buy" ? "+" : "-"} */}
          {item?.sharesCount}
        </span>
      ),
    },
    {
      title: "Wartość udziałów",
      width: 100,
      render: (item: SharesByCompanyType) =>
        item?.sharesValue ? (
          <span className={`transactions-table__number `}>
            {item?.sharesValue}{" "}
            {company?.currencyUuid &&
              getCurrencyFromUuid(company?.currencyUuid)}
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: "Wartość jednego udziału",
      width: 100,
      render: (item: SharesByCompanyType) =>
        item?.sharesValue ? (
          <span className={`transactions-table__number `}>
            {item?.pricePerShare}{" "}
            {company?.currencyUuid &&
              getCurrencyFromUuid(company?.currencyUuid)}
          </span>
        ) : (
          "-"
        ),
    },
    {
      width: 40,
      render: (item: SharesByCompanyType) => {
        return item?.file ? (
          <span
            className="clients-table__download"
            onClick={() =>
              downloadFile({ name: item.file.name, url: item.file.url })
            }
          >
            <SVG type={SVG_ENUM.FILE} />
          </span>
        ) : null;
      },
    },
  ];
};
export const summaryColumns = (item: CompanyType) => [
  {
    key: "type",
    width: 315,
    render: () => {
      return "Razem";
    },
  },
  {
    width: 200,
    render: (item: CompanyMyType) =>
      item?.sharesCount || (item.shares && item.shares[0]?.sharesCount),
  },
  {
    width: 190,
    render: (item: CompanyMyType) =>
      item?.sharesValue || (item.shares && item.shares[0]?.sharesValue) ? (
        <>
          {" "}
          {item?.sharesValue ||
            (item.shares && item.shares[0]?.sharesValue)}{" "}
          {getCurrencyFromUuid(item?.currencyUuid)}{" "}
        </>
      ) : (
        "-"
      ),
  },

  {
    width: 30,
  },
  {
    width: 40,
  },
];
