import { ContextMenu, SVG, CompanyStatus, PacketBadge } from "shared";
import { PACKET_ENUM, SVG_ENUM } from "enums";
import {
  CompanyMyType,
  CompanyStatusType,
  CompanyType,
} from "types/CompanyTypes";
import moment from "moment";
import { getCurrencyFromUuid, getIndustryFromUuid } from "utils";
import { MarketInfoSpeaker } from "features/marketInfo";

const items = ({
  setEditCompany,
  handleStatusChange,
  setModalManage,
  isAdmin,
}: {
  setEditCompany: (company: CompanyType) => void;
  handleStatusChange: (
    company: CompanyType,
    status: CompanyStatusType["status"]
  ) => void;
  setModalManage: ({
    type,
    company,
  }: {
    type: string;
    company: CompanyType | CompanyMyType | null;
  }) => void;
  isAdmin: boolean;
}) => {
  return (company: CompanyType) => {
    const actions = [];

    if (company.status !== "withdrawn") {
      actions.push({
        name: "Edytuj",
        action: () => setEditCompany(company),
        svg: SVG_ENUM.EDIT,
      });

      if (company.status !== "hidden") {
        actions.push({
          name: "Ukryj",
          action: () => handleStatusChange(company, "hidden"),
          svg: SVG_ENUM.CROSSED_EYE,
        });
      } else {
        actions.push({
          name: "Pokaż",
          action: () => handleStatusChange(company, "active"),
          svg: SVG_ENUM.EYE,
        });
      }
      actions.push({
        name: "Wycofaj",
        action: () => handleStatusChange(company, "withdrawn"),
        svg: SVG_ENUM.STOP_SIGN,
      });
    }

    if (company.status === "withdrawn") {
      actions.push({
        name: "Przywróć",
        action: () => handleStatusChange(company, "active"),
        svg: SVG_ENUM.ARROW_UNDO,
      });
    }

    if (company.status === "active" && isAdmin) {
      actions.push({
        name: "Dodaj transakcję",
        action: () => setModalManage({ company, type: "addClient" }),
        svg: SVG_ENUM.ADD_PLUS,
      });
    }

    return actions;
  };
};

export const mobileColumns = ({
  setEditCompany,
  handleStatusChange,
  isAdmin = false,
  setModalManage,
  unviewedPostCount,
}: {
  setEditCompany: (company: CompanyType) => void;
  handleStatusChange: (
    company: CompanyType,
    status: CompanyStatusType["status"]
  ) => void;
  isAdmin?: boolean;
  setModalManage: ({
    type,
    company,
  }: {
    type: string;
    company: CompanyType | CompanyMyType | null;
  }) => void;
  unviewedPostCount?: { [key: string]: number };
}) => [
  {
    width: "calc(50% - 10px)",
    render: (values: any) => (
      <div className="users-table-mobile__name">{`${values.name}`}</div>
    ),
  },
  {
    dataIndex: "status",
    key: "status",
    width: 100,
    render: (value: CompanyStatusType["status"]) =>
      isAdmin ? <CompanyStatus status={value} /> : null,
  },
  {
    width: 88,
    render: (item: CompanyType) =>
      isAdmin ? (
        <ContextMenu
          data={item}
          items={items({
            setEditCompany,
            handleStatusChange,
            setModalManage,
            isAdmin,
          })(item)}
        >
          <SVG type={SVG_ENUM.DOTS} />
        </ContextMenu>
      ) : (
        <MarketInfoSpeaker
          id={item.id}
          postsCount={unviewedPostCount?.[item.id] || 0}
          origin={item.origin}
        />
      ),
  },
];
export const mobileColumnsOthers = ({ isAdmin }: { isAdmin: boolean }) => {
  if (isAdmin) {
    return [
      {
        width: "100%",
        render: (values: any) => {
          const user =
            values.shares && values.shares[0] && values.shares[0].user;
          const industry = getIndustryFromUuid(values.industryUuid);
          return (
            <div className="companies-table__other-mobile">
              <div className="companies-table__other-mobile-title">
                <h3>{values.name}</h3>{" "}
                {user?.package === PACKET_ENUM.PREMIUM ||
                user?.package === PACKET_ENUM.PRO ? (
                  <PacketBadge packetType={user?.package} />
                ) : null}
              </div>
              <div className="companies-table__other-mobile-content">
                <p>Branża: </p>
                <p>{industry}</p>
                <p>Klient: </p>
                <p>{user?.name || "Brak Danych"}</p>
                <p>Ilość udziałów:</p>
                <p>
                  {values.shares &&
                  values.shares[0] &&
                  values.isHistoryAccepted &&
                  values.shares[0].sharesCount !== null
                    ? `${values.shares[0].sharesCount}`
                    : `Brak informacji`}
                </p>
                <p>Waluta:</p>
                <p>{getCurrencyFromUuid(values.currencyUuid)}</p>
              </div>
            </div>
          );
        },
      },
    ];
  } else {
    return [
      {
        width: "calc(50% - 10px)",
        render: (values: any) => (
          <div className="users-table-mobile__name">{`${values.name}`}</div>
        ),
      },
    ];
  }
};

export const desktopColumns = ({
  setEditCompany,
  handleStatusChange,
  isAdmin = false,
  setModalManage,
  unviewedPostCount,
}: {
  setEditCompany: (company: CompanyType) => void;
  handleStatusChange: (
    company: CompanyType,
    status: CompanyStatusType["status"]
  ) => void;
  isAdmin?: boolean;
  setModalManage: ({
    type,
    company,
  }: {
    type: string;
    company: CompanyType | CompanyMyType | null;
  }) => void;
  unviewedPostCount?: { [key: string]: number };
}) => {
  let result = [
    {
      key: "index",
      width: 40,
      render: (value: any, record: CompanyType, index: number) => (
        <div
          data-tooltip-id="company-tooltip"
          data-tooltip-content={record.description}
          className="companies-table__info"
        >
          <SVG type={SVG_ENUM.INFO} />
        </div>
      ),
    },
    {
      title: "Nazwa spółki",
      dataIndex: "name",
      key: "name",
      width: "250px",
      minWidth: 250,
    },
    {
      title: "Branża",
      width: 130,
      render: (value: any, record: CompanyType, index: number) => {
        return `${getIndustryFromUuid(record.industryUuid)}`;
      },
    },
    {
      title: "Początkowa ilość akcji/udziałów",
      width: 150,
      access: "admin",
      render: (value: any, record: CompanyType, index: number) =>
        `${record.initialSharesCount || "Brak danych"}`,
    },
    {
      title: "Pozostała ilość akcji/udziałów",
      width: 120,
      render: (value: any, record: CompanyType, index: number) =>
        `${record.availableShares || "Brak danych"}`,
    },
    {
      title: "Waluta",
      width: 60,
      render: (value: any, record: CompanyType, index: number) =>
        `${getCurrencyFromUuid(record.currencyUuid)}`,
    },
    {
      title: "Data publikacji na giełdzie",
      width: 170,
      render: (value: any, record: CompanyType, index: number) =>
        record.publicationDate
          ? `${moment(record.publicationDate).format("DD/MM/YYYY")}r.`
          : null,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (value: CompanyStatusType["status"]) => (
        <CompanyStatus status={value} />
      ),
    },

    {
      width: 88,
      render: (item: CompanyType) => (
        <ContextMenu
          data={item}
          items={items({
            setEditCompany,
            handleStatusChange,
            setModalManage,
            isAdmin,
          })(item)}
        >
          <SVG type={SVG_ENUM.DOTS} />
        </ContextMenu>
      ),
    },
  ];

  if (!isAdmin) {
    result.splice(result.length - 2, 1);
    //remove last column
    result.pop();
    result.push({
      title: "",
      dataIndex: "",
      key: "status",
      width: 40,
      //@ts-ignore
      render: (value: any, record: CompanyMyType) => (
        <MarketInfoSpeaker
          id={record.id}
          postsCount={unviewedPostCount?.[record.id] || 0}
          origin={record.origin}
        />
      ),
    });

    result = result.filter((item) => item.access !== "admin");
  }
  return result;
};

export const desktopColumnsOtherForAdmin = () => {
  let result = [
    {
      key: "index",
      width: 40,
      render: (value: any, record: CompanyType, index: number) => (
        <div
          data-tooltip-id="company-tooltip"
          data-tooltip-content={record.description}
          className="companies-table__info"
        >
          <SVG type={SVG_ENUM.INFO} />
        </div>
      ),
    },
    {
      title: "Nazwa spółki",
      dataIndex: "name",
      key: "name",
      width: "250px",
      minWidth: 250,
    },
    {
      title: "Branża",
      width: 130,
      render: (value: any, record: CompanyType, index: number) => {
        return `${getIndustryFromUuid(record.industryUuid)}`;
      },
    },
    {
      title: "Ilość udziałów",
      width: 150,
      render: (value: any, record: CompanyType, index: number) =>
        record.shares &&
        record.shares[0] &&
        record.isHistoryAccepted &&
        record.shares[0].sharesCount !== null
          ? `${record.shares[0].sharesCount}`
          : `Brak informacji`,
    },
    {
      title: "Waluta",
      width: 60,
      render: (value: any, record: CompanyType, index: number) =>
        `${getCurrencyFromUuid(record.currencyUuid)}`,
    },

    {
      title: "Klient",
      width: 170,
      render: (value: any, record: CompanyType, index: number) => {
        const user = record.shares && record.shares[0] && record.shares[0].user;
        return user ? (
          <p>
            {user?.name}{" "}
            {user.package === PACKET_ENUM.PREMIUM ||
            user.package === PACKET_ENUM.PRO ? (
              <PacketBadge packetType={user.package} />
            ) : null}
          </p>
        ) : (
          `Brak informacji`
        );
      },
    },
  ];

  return result;
};
