import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { queryClient } from "App";
import axiosInstance from "axiosConfig";
import { TOAST_TYPES_ENUM } from "enums";
import { Toast } from "shared";

interface UpdateInvoiceSettingsParams {
  isSendInvoice: boolean;
  invoiceEmail?: string;
}

export const useUpdateInvoiceSettings = (
  options?: UseMutationOptions<any, Error, UpdateInvoiceSettingsParams>
) => {
  return useMutation({
    mutationKey: ["updateInvoiceSettings"],
    mutationFn: async (values: UpdateInvoiceSettingsParams) => {
      return axiosInstance.put(`users/invoice`, values).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Ładowanie...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "updateInvoiceSettings",
      });

      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Zaktualizowano ustawienia",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "updateInvoiceSettings",
      });
      queryClient.prefetchQuery({ queryKey: ["me"] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },

    onError: (error, variables, context) => {
      Toast({
        message: "Błąd podczas aktualizacji ustawień",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "updateInvoiceSettings",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
