import { PACKET_ENUM, SVG_ENUM } from "enums";
import { useGetMe } from "hooks/auth";
import { PostTypeType } from "screens/marketInfoPage/MarketInfoPage";
import { SVG } from "shared";
import "./PostTypeSwitch.scss";
import { PacketModal } from "features/users";
import { useState } from "react";

interface Props {
  type: PostTypeType;
  setType: React.Dispatch<React.SetStateAction<PostTypeType>>;
  setModalManage: React.Dispatch<React.SetStateAction<any>>;
}

const PostTypeSwitch = ({ type, setType, setModalManage }: Props) => {
  const { data: authorizedUser } = useGetMe();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isRelianceLocked =
    !authorizedUser?.package || authorizedUser?.package === PACKET_ENUM.BASIC;
  const isOtherLocked =
    !authorizedUser?.package ||
    authorizedUser?.package === PACKET_ENUM.BASIC ||
    authorizedUser?.package === PACKET_ENUM.STANDARD;

  const handleTypeChange = (type: PostTypeType) => {
    setType(type);
    sessionStorage.setItem("selectedPostsCompanyOrigin", type);
  };

  return (
    <>
      <PacketModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        parent="homepage"
      />
      <span
        onClick={() => handleTypeChange("all")}
        className={`dashboard__tab ${
          type === "all" ? "dashboard__tab--active" : ""
        } `}
      >
        Wszystkie
      </span>
      <span
        className={`dashboard__tab ${
          type === "my" ? "dashboard__tab--active" : ""
        }`}
        onClick={() => handleTypeChange("my")}
      >
        Spółki wykupione w Reliance
      </span>
      <span
        className={`dashboard__tab 
                ${type === "reliance" ? "dashboard__tab--active" : ""}
                ${isRelianceLocked ? "dashboard__tab--locked" : ""}
                `}
        onClick={() => !isRelianceLocked && handleTypeChange("reliance")}
      >
        Spółki w ofercie Reliance
        {isRelianceLocked && <SVG type={SVG_ENUM.LOCK} />}
      </span>
      <div className="dashboard__tab-wrapper">
        <span
          className={`dashboard__tab 
          ${type === "other" ? "dashboard__tab--active" : ""}
          ${isOtherLocked ? "dashboard__tab--locked" : ""}
          `}
          onClick={() => !isOtherLocked && handleTypeChange("other")}
        >
          Spółki wykupione poza Reliance
          {isOtherLocked && <SVG type={SVG_ENUM.LOCK} />}
        </span>
        {isOtherLocked && (
          <div className="dashboard__tab-tooltip">
            <b
              style={{ cursor: "pointer" }}
              onClick={() => setModalManage({ type: "packet", post: null })}
            >
              Wykup wyższy pakiet
            </b>
            , aby odblokować funkcję
          </div>
        )}
      </div>
    </>
  );
};

export default PostTypeSwitch;
