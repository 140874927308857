import { SideModal } from "shared";
import { CompanyMyType } from "types/CompanyTypes";
import "./TransactionsSideModal.scss";
import TransactionsTable from "./components/TransactionsTable";
import { useGetMe } from "hooks/auth";

interface TransactionsSideModalProps {
  isOpen: boolean;
  onClose: () => void;
  company: CompanyMyType | null;
}

const TransactionsSideModal: React.FC<TransactionsSideModalProps> = ({
  isOpen,
  onClose,
  company,
}) => {
  const client =
    company && company.shares && company.shares[0] && company?.shares[0]?.user;
  const { data: authorizedUser } = useGetMe();
  const isAdmin = authorizedUser?.role === "admin";
  return (
    <SideModal
      isOpen={isOpen}
      onClose={onClose}
      title="Lista transakcji"
      subtitle={
        isAdmin ? (
          <p>
            <b>Spółka:</b> {company?.name || "-"} | <b>Klient:</b>{" "}
            {client?.name || "-"}
          </p>
        ) : (
          <p>
            <b>Spółka:</b> {company?.name || "-"}{" "}
          </p>
        )
      }
      className="company-info-modal wide"
    >
      <>
        <TransactionsTable company={company} />
      </>
    </SideModal>
  );
};

export default TransactionsSideModal;
