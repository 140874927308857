import { VALIDATION_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useStoreShare } from "hooks/shares";
import { useGetClientsOptions } from "hooks/users";
import { useState } from "react";
import moment from "moment";
import {
  Button,
  CalendarInput,
  DropFileInput,
  FullscreenModal,
  Input,
  SelectComponent,
  Warning,
} from "shared";
import { CompanyType } from "types/CompanyTypes";
import { getCurrencyFromUuid, getCurrencySymbol } from "utils";
import * as Yup from "yup";
import "./AddShares.scss";

interface AddSharesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  company?: CompanyType | null;
}

const AddSharesModal: React.FC<AddSharesModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  company,
}) => {
  const { mutateAsync: storeShare } = useStoreShare();
  const { data: clientOptions } = useGetClientsOptions(isOpen);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    userId: "",
    companyId: company?.id ? company.id : "",
    sharesCount: 0,
    sharesValue: 0,
    invoice: null,
    completedAt: moment().format("YYYY-MM-DD"),
    pricePerShare: company?.pricePerShare || null,
  };

  const onSubmit = async (values: typeof initialValues | any) => {
    setIsSubmitting(true);
    try {
      const summary =
        values.sharesValue && values.sharesCount
          ? Number.parseFloat(
              (values.sharesValue / values.sharesCount).toFixed(2)
            )
          : 0;

      await storeShare(
        { ...values, pricePerShare: summary },
        {
          onSuccess: () => {
            onSuccess && onSuccess();
            onClose();
          },
        }
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const validation = () =>
    Yup.object().shape({
      userId: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      sharesCount: Yup.number()
        .required(VALIDATION_ENUM.REQUIRED)
        .min(1, VALIDATION_ENUM.MIN_1),
      sharesValue: Yup.number()
        .required(VALIDATION_ENUM.REQUIRED)
        .min(1, VALIDATION_ENUM.MIN_1),
      invoice: Yup.mixed().required(VALIDATION_ENUM.REQUIRED),
      completedAt: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    });

  return (
    <FullscreenModal isOpen={isOpen} onClose={onClose}>
      <h2 className="fullscreen-modal__header">
        Dodawanie transakcji spółki <i>{company?.name}</i>
      </h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={validation()}
        enableReinitialize
      >
        {({ errors, values, handleSubmit }) => {
          const isMoreThanAvailable =
            (company?.availableShares === 0 && values.sharesCount > 0) ||
            (company?.availableShares &&
              company?.availableShares < values.sharesCount);

          const summary =
            values.sharesValue && values.sharesCount
              ? (values.sharesValue / values.sharesCount).toFixed(2)
              : 0;
          const isStock = company?.type === "stock";
          return (
            <Form className="m-width-100">
              <Field
                label="Klient"
                name="userId"
                id="userId"
                as={SelectComponent}
                options={clientOptions}
                isSearchable
              />

              <Field
                type="number"
                id="sharesCount"
                name="sharesCount"
                precision={3}
                label={`Ilość ${isStock ? "akcji" : "udziałów"}`}
                as={Input}
              />

              <Warning
                className={isMoreThanAvailable ? "" : "warning--gray"}
                text={`Nie możesz sprzedać więcej udziałów niż pozostało. Pozostało:  ${company?.availableShares}`}
              />

              <div className="input--currency">
                <Field
                  type="number"
                  id="sharesValue"
                  name="sharesValue"
                  label={`Wartość ${isStock ? "akcji" : "udziałów"}`}
                  maxLength={12}
                  precision={2}
                  as={Input}
                />
                <span>
                  {company &&
                    getCurrencySymbol(
                      getCurrencyFromUuid(company?.currencyUuid)
                    )}
                </span>
              </div>
              <div className={`add-share__summary`}>
                <label className="input__label">
                  Wartość {isStock ? "jednej akcji" : "jednego udziału"}
                </label>
                <input
                  type="text"
                  className="input"
                  readOnly
                  value={summary ? summary : ""}
                />
                <span>
                  {company &&
                    getCurrencySymbol(
                      getCurrencyFromUuid(company?.currencyUuid)
                    )}
                </span>
              </div>

              <Field
                type="date"
                id="completedAt"
                name="completedAt"
                label={"Data transakcji"}
                component={CalendarInput}
                errors={errors}
              />

              <Field
                label="Umowa"
                name="invoice"
                required
                id="invoice"
                as={DropFileInput}
                accept=".pdf"
              />

              <div className="fullscreen-modal__buttons">
                <Button
                  label="Anuluj"
                  onClick={onClose}
                  className="button--stroke button--rounded"
                />
                <Button
                  label={"Dodaj"}
                  type="submit"
                  disabled={!!isMoreThanAvailable}
                  onClick={() => null}
                  className=" button--rounded button--black"
                  isLoading={isSubmitting}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </FullscreenModal>
  );
};

export default AddSharesModal;
