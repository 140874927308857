import React, { useEffect, useRef } from "react";
import {
  Button,
  Input,
  LoginWrapper,
  PhoneWithPrefix,
  SelectComponent,
} from "shared";
import "./LoginPage.scss";
import { Field, Formik, Form } from "formik";
import { ROUTE_ENUM, VALIDATION_ENUM } from "enums";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useGetPhonePrefixes } from "hooks/phone";
import { useGetMe, useGetSmsCode } from "hooks/auth";
import { checkIfLogged } from "utils";
import { validatePhoneNumber } from "utils/validation/phoneValidation";

type LoginPageProps = {};

const LoginPage: React.FC<LoginPageProps> = () => {
  const { data: phonePrefixes } = useGetPhonePrefixes();
  const navigate = useNavigate();
  const formRef = useRef<any>(null);
  const { data: authorizedUser } = useGetMe();
  const prefix = phonePrefixes?.find(
    (prefix) => prefix.value === formRef.current?.values.phonePrefixUuid
  );
  const { mutate } = useGetSmsCode({
    onSuccess: () => {
      navigate(ROUTE_ENUM.ENTER_CODE, {
        state: {
          phoneNumber: ` ${prefix?.label} ${formRef.current.values.phoneNumber}`,
          phonePrefixUuid: formRef.current.values.phonePrefixUuid,
          phone: `${formRef.current.values.phoneNumber}`,
        },
      });
    },
    onError: (error: Error) => {
      console.error("Błąd podczas logowania:", error.message);
    },
  });

  useEffect(() => {
    if (authorizedUser) {
      checkIfLogged(authorizedUser, navigate);
    }
  }, [authorizedUser, navigate]);

  const validation = () =>
    Yup.object().shape({
      phoneNumber: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .min(9, VALIDATION_ENUM.MIN_9)
        .max(12, VALIDATION_ENUM.MAX_12)
        .test("is-digitals-only", VALIDATION_ENUM.NUMBER, (value) => {
          return /^\d+$/.test(value);
        })
        .test("phone-validation", VALIDATION_ENUM.NUMBER, function (value) {
          if (!/^\d+$/.test(value)) {
            return false;
          }

          const { phonePrefixUuid } = this.parent;

          if (value && phonePrefixUuid && phonePrefixes?.length) {
            const validation = validatePhoneNumber(
              value,
              phonePrefixUuid,
              phonePrefixes
            );

            if (!validation.isValid) {
              return this.createError({
                message: validation.error || VALIDATION_ENUM.NUMBER,
              });
            }
          }

          return true;
        }),
      phonePrefixUuid: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    });

  const initialValues = {
    phoneNumber: "",
    phonePrefixUuid: "",
  };

  const onSubmit = (values: typeof initialValues) => {
    mutate(values);
  };

  useEffect(() => {
    if (phonePrefixes.length && formRef.current) {
      const pl = phonePrefixes.find((prefix) => prefix.label === "+48");
      formRef.current.setFieldValue("phonePrefixUuid", pl?.value);
    }
  }, [phonePrefixes]);

  return (
    <LoginWrapper>
      <h1 className="login-wrapper__header">Zaloguj się</h1>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={validation()}
        innerRef={formRef}
      >
        {({ errors, values, handleSubmit, setFieldValue }) => (
          <Form>
            <PhoneWithPrefix>
              <Field
                label="Telefon"
                name="phonePrefixUuid"
                id="phonePrefixUuid"
                as={SelectComponent}
                options={phonePrefixes}
              />
              <Field
                name="phoneNumber"
                type="number"
                id="phoneNumber"
                maxLength="12"
                as={Input}
              />
            </PhoneWithPrefix>

            <Button
              label="Wyślij kod dostępu"
              className="button--black button--rounded login-wrapper__submit button--wide"
              onClick={() => {
                handleSubmit();
              }}
            />
            <p
              className="login-wrapper__anchor"
              onClick={() => navigate(ROUTE_ENUM.REGISTER)}
            >
              Nie masz jeszcze konta? <span> Zarejestruj się</span>
            </p>
          </Form>
        )}
      </Formik>
    </LoginWrapper>
  );
};

export default LoginPage;
