// SideModal.tsx
import React, { ReactNode, useEffect, useRef } from "react";
import { Transition } from "react-transition-group";
import "./SideModal.scss";
import SVG from "shared/SVG";
import { SVG_ENUM } from "enums";

interface SideModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
  subtitle?: string | JSX.Element;
  className?: string;
}

const SideModal: React.FC<SideModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
  subtitle,
  className = "",
}) => {
  const duration = 500;

  const defaultStyle = {
    transition: `transform ${duration}ms ease-in-out`,
    transform: "translateX(150%)",
  };

  const transitionStyles: { [key: string]: React.CSSProperties } = {
    entering: { transform: "translateX(0%)" },
    entered: { transform: "translateX(0%)" },
    exiting: { transform: "translateX(150%)" },
    exited: { transform: "translateX(150%)" },
  };
  const sideModalRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (e: React.MouseEvent) => {
    if (e.target instanceof Node) {
      if (!sideModalRef.current?.contains(e.target)) {
        onClose();
      }
    }
  };
  const html = document.querySelector("html");
  useEffect(() => {
    if (isOpen) {
      html?.classList.add("modal-open");
    } else {
      html?.classList.remove("modal-open");
    }
  }, [isOpen, html]);

  return (
    <Transition in={isOpen} timeout={duration}>
      {(state) => (
        <>
          <div
            className={`side-modal__outside ${isOpen ? "active" : ""}`}
            onClick={(e) => handleOutsideClick(e)}
          />
          <div
            className={`side-modal ${className}`}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            ref={sideModalRef}
          >
            <div className="side-modal__header-wrapper">
              <SVG
                type={SVG_ENUM.LOGIN_BG_MOBILE}
                className="side-modal__background"
              />
              <div className="side-modal__header">
                <div className="side-modal__header-left">
                  <h3 className="side-modal__title">{title}</h3>
                  {subtitle ? (
                    <p className="side-modal__subtitle">{subtitle}</p>
                  ) : null}
                </div>
                <span className="side-modal__close" onClick={() => onClose()}>
                  <SVG type={SVG_ENUM.CLOSE} />
                </span>
              </div>
            </div>
            <div className="side-modal__content">{children}</div>
          </div>
        </>
      )}
    </Transition>
  );
};

export default SideModal;
