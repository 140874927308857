import { FullscreenModal } from "shared";
import {
  AccountFromStep,
  ChoosePacketStep,
  EnterSmsCodeStep,
  PaymentMethodStep,
} from "./components";
import { useEffect, useState } from "react";
import "./PacketModal.scss";
import { useGetMe } from "hooks/auth";
import { useNavigate } from "react-router";
import { ROUTE_ENUM } from "enums";

export type ParentType = "payment" | "homepage";

export type PacketModalSteps =
  | "ChoosePacketStep"
  | "AccountFromStep"
  | "EnterSmsCodeStep"
  | "PaymentMethodStep";

interface PacketModalProps {
  isOpen: boolean;
  onClose: () => void;
  parent: ParentType;
}

const PacketModal: React.FC<PacketModalProps> = ({
  isOpen,
  onClose,
  parent,
}) => {
  const [step, setStep] = useState(1);
  const [maxSteps, setMaxSteps] = useState(4);
  const [formData, setFormData] = useState<any>(null);
  const { data: me } = useGetMe();
  const navigate = useNavigate();
  const [stepName, setStepName] =
    useState<PacketModalSteps>("ChoosePacketStep");

  const [stepOptions, setStepOptions] = useState({
    ChoosePacketStep: { data: me?.package || null },
    AccountFromStep: { data: null },
  });

  useEffect(() => {
    let calculatedMaxSteps = 4;
    if (me) calculatedMaxSteps = calculatedMaxSteps - 2;
    setMaxSteps(calculatedMaxSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNextStep = () => {
    if (step === maxSteps) {
      navigate(ROUTE_ENUM.COMPANIES);
      setStep(1);
      onClose();
      return;
    }
    setStep((prevStep) => Math.min(prevStep + 1, maxSteps));
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const isFromHomepage = parent === "homepage";

  const getStep = (step: number): JSX.Element | null => {
    if (step === 1) {
      if (stepName !== "ChoosePacketStep") setStepName("ChoosePacketStep");
      return (
        <ChoosePacketStep
          goNextStep={handleNextStep}
          onClose={onClose}
          parent={parent}
          setStepOptions={setStepOptions}
          stepOptions={stepOptions}
          setFormData={setFormData}
        />
      );
    }
    if (step === 2 && !me) {
      if (stepName !== "AccountFromStep") setStepName("AccountFromStep");
      return (
        <AccountFromStep
          goToNextStep={handleNextStep}
          setStepOptions={setStepOptions}
          goToPreviousStep={handlePreviousStep}
          stepOptions={stepOptions}
          formData={formData}
        />
      );
    }

    if (step === 3 && !me) {
      if (stepName !== "EnterSmsCodeStep") setStepName("EnterSmsCodeStep");
      return (
        <EnterSmsCodeStep
          goToNextStep={handleNextStep}
          goToPreviousStep={handlePreviousStep}
          setStepOptions={setStepOptions}
          stepOptions={stepOptions}
        />
      );
    }

    if (step === 4 || (step === 2 && me)) {
      if (stepName !== "PaymentMethodStep") setStepName("PaymentMethodStep");
      return <PaymentMethodStep goToNextStep={handleNextStep} />;
    }
    return null;
  };

  return (
    <FullscreenModal
      className={`fullscreen-modal--wide packet-modal packet-modal--${stepName}`}
      isOpen={isOpen}
      onClose={() => {
        if (!isFromHomepage) {
          setStep(1);
        }
        sessionStorage.removeItem("packet");
        onClose();
      }}
    >
      {isFromHomepage && (
        <p className="fullscreen-modal__step">
          Krok {step}/<span>{maxSteps}</span>
        </p>
      )}
      {getStep(step)}
    </FullscreenModal>
  );
};

export default PacketModal;
