/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { ROLES_ENUM, ROUTE_ENUM } from "enums";
import { useGetMe } from "hooks/auth";
import { ProtectedRouteProps } from "types/routeTypes";
import DashboardPage from "screens/dashboardPage/DashboardPage";

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAdminOnly = false,
}) => {
  const location = useLocation();
  const { data: authorizedUser, isLoading, refetch, isStale } = useGetMe();

  useEffect(() => {
    isStale && refetch();
  }, [location]);

  if (isLoading) {
    return null;
  }

  const isAuthenticated = !!authorizedUser?.role;
  const role = authorizedUser?.role;
  const isCorrect = isAdminOnly
    ? isAuthenticated && role === ROLES_ENUM.ADMIN
    : isAuthenticated;

  const user =
    sessionStorage.getItem("loginAdmin") || location.search.includes("isAdmin");
  let route;
  const currentPath = `${location.pathname}${location.search}`;
  if (!isCorrect) {
    sessionStorage.setItem("destinationPath", currentPath);
  }
  if (user) {
    route = ROUTE_ENUM.LOGIN_ADMIN;
  } else {
    route = ROUTE_ENUM.LOGIN;
  }

  return isCorrect ? <DashboardPage /> : <Navigate to={route} />;
};

export default ProtectedRoute;
