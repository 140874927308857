import { Field, Formik } from "formik";
import { SelectComponent } from "shared";
import { useGetCompaniesOptions } from "hooks/companies";

interface CompanySelectFormProps {
  companyId: number | null;
  setCompanyId: (id: number | null) => void;
  className?: string;
}

export const CompanySelectForm = ({
  companyId,
  setCompanyId,
  className = "",
}: CompanySelectFormProps) => {
  const { data: companiesOptions } = useGetCompaniesOptions({});

  return (
    <Formik
      initialValues={{ companyId }}
      onSubmit={(values: any) => console.log(values)}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <form
          className={`posts-grid__search ${className}`}
          onSubmit={handleSubmit}
        >
          <Field
            label="Znajdź spółkę"
            name="companyId"
            id="companyId"
            placeholder="Szukaj"
            as={SelectComponent}
            onSelectChange={(e: any) => {
              setCompanyId(e?.value);
              sessionStorage.setItem("selectedPostsCompany", e?.value);
            }}
            options={companiesOptions}
            isClearable
          />
        </form>
      )}
    </Formik>
  );
};
