import { VALIDATION_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useStoreCompany, useUpdateCompany } from "hooks/companies";
import { useGetCountriesCurrency } from "hooks/countries";
import { useGetIndustriesHook } from "hooks/industries";
import { useRef, useState } from "react";
import "./AddCompany.scss";
// import { useAddAdmin, useUpdateUser } from "hooks/users";
import {
  Button,
  CalendarInput,
  FullscreenModal,
  Input,
  LargeSwitch,
  SelectComponent,
  Textarea,
} from "shared";
import { CompanyStoreType, CompanyType } from "types/CompanyTypes";
import {
  companyStatusOpitons,
  getCurrencyFromUuid,
  getCurrencySymbol,
  scrollToError,
} from "utils";
import * as Yup from "yup";

interface AddCompanyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  company?: CompanyType | null;
}

const AddCompanyModal: React.FC<AddCompanyModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  company,
}) => {
  const { mutateAsync: storeCompany } = useStoreCompany();
  const { mutateAsync: updateCompany } = useUpdateCompany();
  const { data: industries } = useGetIndustriesHook();
  const { data: currencyOptions } = useGetCountriesCurrency();
  const formRef = useRef<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    name: company?.name || "",
    description: company?.description || "",
    industryUuid: company?.industryUuid || "",
    initialSharesCount: company?.initialSharesCount || null,
    currencyUuid: company?.currencyUuid || "",
    sharePrice: company?.sharePrice || null,
    pricePerShare: company?.pricePerShare || null,
    publicationDate: company?.publicationDate || null,
    status: company?.status || null,
    type: company?.type || "stock",
    nip: company?.nip || "",
  };

  const onSubmit = async (values: CompanyStoreType) => {
    setIsSubmitting(true);
    try {
      const summary =
        values.sharePrice && values.initialSharesCount
          ? Number.parseFloat(
              (values.sharePrice / values.initialSharesCount).toFixed(2)
            )
          : 0;

      if (!!company) {
        await updateCompany(
          {
            ...company,
            ...values,
            id: company.id,
            pricePerShare: summary,
            availableShares: undefined,
          },
          {
            onSuccess: () => {
              // onSuccess && onSuccess();
              onClose();
            },
          }
        );
      } else {
        await storeCompany(
          { ...values, pricePerShare: summary },
          {
            onSuccess: () => {
              onSuccess && onSuccess();
              onClose();
            },
          }
        );
      }
    } finally {
      scrollToError(formRef.current?.errors);
      setIsSubmitting(false);
    }
  };

  const validation = () =>
    Yup.object().shape({
      name: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .max(50, VALIDATION_ENUM.MAX_50)
        .min(3, VALIDATION_ENUM.MIN3)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
      description: Yup.string()
        .min(3, VALIDATION_ENUM.MIN3)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
      industryUuid: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      initialSharesCount: Yup.number()
        .required(VALIDATION_ENUM.REQUIRED)

        .min(1, VALIDATION_ENUM.MIN_1),
      currencyUuid: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      sharePrice: Yup.number()
        .required(VALIDATION_ENUM.REQUIRED)

        .min(1, VALIDATION_ENUM.MIN_1),
      status: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      type: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      nip: Yup.string().max(11),
    });

  return (
    <FullscreenModal isOpen={isOpen} onClose={onClose}>
      <h2 className="fullscreen-modal__header">
        {!!company ? "Edycja spółki" : "Dodaj spółkę"}
      </h2>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values: any) => await onSubmit(values)}
        validationSchema={validation()}
        enableReinitialize
        innerRef={formRef}
        validateOnMount={false}
      >
        {({
          errors,
          values,
          handleSubmit,
          setFieldValue,
          submitForm,
          isValid,
          submitCount,
        }) => {
          const summary =
            !!values?.sharePrice &&
            !!values?.initialSharesCount &&
            values?.sharePrice > 0 &&
            values?.initialSharesCount > 0
              ? (values?.sharePrice / values?.initialSharesCount).toFixed(2)
              : 0;
          const isStock = values?.type === "stock";
          return (
            <Form className="m-width-100 fullscreen-modal__select-margin">
              <LargeSwitch
                state={values.type}
                className="add-company__switch"
                options={[
                  { label: "Akcje", value: "stock" },
                  { label: "Udziały", value: "share" },
                ]}
                onChange={(value: string) => setFieldValue("type", value)}
              />
              <Field
                type="text"
                id="name"
                name="name"
                label={"Nazwa spółki"}
                as={Input}
                maxLength={50}
              />

              <Field
                type="textarea"
                id="description"
                optional
                name="description"
                label={"Opis"}
                component={Textarea}
                maxLength={1000}
                errors={errors}
              />

              <Field
                label="Branża"
                name="industryUuid"
                id="industryUuid"
                as={SelectComponent}
                options={industries}
              />

              <Field
                type="date"
                id="publicationDate"
                name="publicationDate"
                label={"Data publikacji na giełdzie"}
                optional
                component={CalendarInput}
              />

              <Field
                label="Waluta"
                name="currencyUuid"
                id="currencyUuid"
                as={SelectComponent}
                options={currencyOptions}
              />

              <Field
                type="number"
                id="initialSharesCount"
                name="initialSharesCount"
                label={`Ilość ${
                  values.type === "stock" ? "akcji" : "udziałów"
                }`}
                as={Input}
                maxLength={10}
              />
              <div className="input--currency">
                <Field
                  type="number"
                  maxLength={10}  
                  id="sharePrice"
                  name="sharePrice"
                  label={`Suma wartości ${
                    values.type === "stock" ? "akcji" : "udziałów"
                  }`}
                  as={Input}
                />
                <span>
                  {getCurrencySymbol(getCurrencyFromUuid(values.currencyUuid))}
                </span>
              </div>
              <div className={`add-share__summary`}>
                <label className="input__label">
                  Wartość {isStock ? "jednej akcji" : "jednego udziału"}
                </label>
                <input
                  type="text"
                  className="input"
                  readOnly
                  value={summary ? summary : 0}
                />
                <span>
                  {values.currencyUuid &&
                    getCurrencySymbol(getCurrencyFromUuid(values.currencyUuid))}
                </span>
              </div>

              <Field
                label="Status"
                name="status"
                id="status"
                as={SelectComponent}
                options={companyStatusOpitons}
              />

              <Field
                type="number"
                id="nip"
                name="nip"
                optional
                label={"NIP"}
                as={Input}
                maxLength={11}
              />
              <div className="fullscreen-modal__buttons">
                <Button
                  label="Anuluj"
                  onClick={onClose}
                  className="button--stroke button--rounded"
                />
                <Button
                  label={"Potwierdź"}
                  type="button"
                  onClick={async () => {
                    await submitForm().then(() => {
                      // After form validation is complete, check if there are errors and scroll to them
                      if (
                        Object.keys(formRef.current?.errors || {}).length > 0
                      ) {
                        scrollToError(formRef.current?.errors);
                      }
                    });
                  }}
                  className=" button--rounded button--black"
                  isLoading={isSubmitting}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </FullscreenModal>
  );
};

export default AddCompanyModal;
