import { CompanyType } from "types/CompanyTypes";
import { getCurrencyFromUuid, getIndustryFromUuid } from "utils";
import moment from "moment";

interface CompanyDetailsTableProps {
  company: CompanyType | null;
  financialView?: boolean;
}

const CompanyDetailsTable: React.FC<CompanyDetailsTableProps> = ({
  company,
  financialView = false,
}) => {
  if (!company) {
    return <div>No company data available</div>;
  }

  return (
    <div className="company-details-table">
      <>
        <h2 className="side-modal__table-heading">Informacje o spółce</h2>
        <table className="side-modal__table">
          <tbody>
            <tr>
              <td className="side-modal__table-label">Branża:</td>
              <td className="side-modal__table-cell">
                {getIndustryFromUuid(company?.industryUuid) || "-"}
              </td>
            </tr>

            <tr>
              <td className="side-modal__table-label">Waluta:</td>
              <td className="side-modal__table-cell">
                {getCurrencyFromUuid(company.currencyUuid) || "-"}
              </td>
            </tr>
            <tr>
              <td className="side-modal__table-label">
                Pozostała ilość akcji/udziałów:
              </td>
              <td className="side-modal__table-cell">
                {company?.availableShares || "-"}
              </td>
            </tr>
            <tr>
              <td className="side-modal__table-label">
                Data publikacji na giełdzie:
              </td>
              <td className="side-modal__table-cell">
                {`${
                  company.publicationDate
                    ? moment(company.publicationDate).format("DD/MM/YYYY") +
                      "r."
                    : "-"
                }`}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    </div>
  );
};

export default CompanyDetailsTable;
