const scrollToError = (errors: any) => {
  if (Object.keys(errors).length > 0) {
    const firstErrorField = Object.keys(errors)[0];
    const element = document.getElementById(firstErrorField)?.parentElement;
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      const inputElement = element.querySelector(
        "input, select, .ce-paragraph"
      );
      if (inputElement) {
        // @ts-ignore
        inputElement.focus();
      }
    }
  }
};

export default scrollToError;
