import { Button, Modal } from "shared";
import { useDeactivateSubscription } from "hooks/subscriptions";
import { CustomerType } from "types/CustomerTypes";
// import { UserType } from "types/UsersTypes";

interface BlockUserType {
  customer?: CustomerType;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

const DeleteSubscriptionModal = ({
  isOpen,
  onClose,
  customer,
  onSuccess,
}: BlockUserType) => {
  const { mutate: deactivateSubscription } = useDeactivateSubscription();

  const handleDeactivateSubscription = async () => {
    if (customer) {
      await deactivateSubscription(customer?.uuid, {
        onSuccess: () => {
          onSuccess && onSuccess();
          onClose();
        },
      });
      onClose();
    }
  };
  return (
    <>
      <Modal isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">Rezygnacja z subskrypcji</div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz zrezygnować ze subskrypcji ?
          </div>
          <div className="modal-buttons">
            <Button
              className="button--stroke button--rounded"
              onClick={() => onClose()}
              label="Anuluj"
            />
            <Button
              className="button--black button--rounded"
              onClick={() => handleDeactivateSubscription()}
              label="Potwierdź"
            />
          </div>
        </>
      </Modal>
    </>
  );
};

export default DeleteSubscriptionModal;
