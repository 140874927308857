import { SVG, AnalyzeStatusBadge } from "shared";
import { SVG_ENUM } from "enums";
import { AnalyzeStatus, AnalyzeType } from "types/AnalyzeTypes";
import moment from "moment";
import { downloadFile } from "utils";

export const mobileColumns = ({
  isAdmin = false,
  setModalManage,
}: {
  isAdmin?: boolean;
  setModalManage: ({
    type,
    analyze,
  }: {
    type: string;
    analyze: AnalyzeType | null;
  }) => void;
}) => {
  const result = [
    {
      dataIndex: "companyName",
      key: "companyName",
      width: 260,
    },
    {
      dataIndex: "status",
      key: "status",
      width: 30,
      render: (value: AnalyzeStatus) => <AnalyzeStatusBadge status={value} />,
    },
  ];
  if (!isAdmin) {
    result.push({
      title: "",
      width: 30,
      // @ts-ignore
      render: (value: AnalyzeType) =>
        value.file ? (
          <span
            className="clients-table__download"
            onClick={() =>
              downloadFile({
                name: value.file.name,
                url: value.file.url,
              })
            }
          >
            <SVG type={SVG_ENUM.FILE} />
          </span>
        ) : null,
    });
  }

  return result;
};

export const desktopColumns = ({
  isAdmin = false,
  setModalManage,
}: {
  isAdmin?: boolean;
  setModalManage: ({
    type,
    analyze,
  }: {
    type: string;
    analyze: AnalyzeType | null;
  }) => void;
}) => {
  let result = [
    {
      title: "Nazwa spółki",
      dataIndex: "companyName",
      key: "companyName",
      width: 140,
    },
    {
      title: "NIP spółki",
      width: 100,
      render: (value: any, record: AnalyzeType, index: number) => {
        return record.nip;
      },
    },
    {
      title: "Data zlecenia",
      width: 100,
      render: (value: any, record: AnalyzeType, index: number) =>
        moment(record.createdAt).format("DD/MM/YYYY"),
    },
    {
      title: "Termin wykonania",
      width: 100,
      render: (value: any, record: AnalyzeType, index: number) => {
        return (
          <span className="companies-table__table-row">
            {moment(record.deadlineAt).format("DD/MM/YYYY")}{" "}
            {record.status === "pending" ? (
              <span className="companies-table__unconfirmed-badge">
                {" "}
                Niepotwierdzony
              </span>
            ) : null}{" "}
          </span>
        );
      },
    },
  ];

  if (isAdmin) {
    result.push({
      title: "Zlecający",
      width: 120,
      // @ts-ignore
      render: (value: any, record: AnalyzeType, index: number) =>
        record?.user?.name,
    });
  }
  //@ts-ignore
  result.push({
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 80,
    render: (value: AnalyzeStatus) => <AnalyzeStatusBadge status={value} />,
  });
  if (!isAdmin) {
    result.push({
      title: "",
      dataIndex: "explanation",
      key: "explanation",
      width: 120,
    });
    result.push({
      title: "",
      width: 40,
      // @ts-ignore
      render: (value: AnalyzeType) =>
        value.fileId ? (
          <span
            className="clients-table__download"
            onClick={() =>
              downloadFile({
                name: value.file.name,
                url: value.file.url,
              })
            }
          >
            <SVG type={SVG_ENUM.FILE} />
          </span>
        ) : null,
    });
  }

  return result;
};
