import "./PostStatusBadge.scss";
import { PostStatusType } from "types/PostTypes";

const PostStatusBadge = ({
  status,
  isPlanned = false,
}: {
  status: PostStatusType;
  isPlanned: boolean;
}) => {
  const statusToText = {
    draft: "Szkic",
    published: isPlanned ? "Zaplanowane" : "Opublikowana",
    withdrawn: "Wycofana",
  };

  const lowerCaseStatus = status.toLowerCase() as keyof typeof statusToText;

  return (
    <span
      className={`post-status post-status--${status} ${
        isPlanned ? "post-status--planned" : ""
      }`}
    >
      <p className="post-status__text">{statusToText[lowerCaseStatus]}</p>
    </span>
  );
};

export default PostStatusBadge;
